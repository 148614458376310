import { Injectable } from "@angular/core";
import * as moment from "moment";
import { combineLatest, from, Observable, of } from "rxjs";

import { MappedTask, Task, TaskState } from "../structs/tasks";
import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { Perimeter } from "../structs/assets";
import { ChangeAction, makeChange } from "@structs/synchronization";
import { getLocalId } from "../structs/utils";
import { SynchronizationService } from "./synchronization.service";
import { SuccessToastService, TASK_OBJECT } from "./success-toast.service";
import { Investment } from "../structs/investments";
import { TranslateService } from "@ngx-translate/core";
import { DocumentRequirement } from "../structs/documents";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { ScopeService } from "./scope.service";

@Injectable()
export class TasksService {
  constructor(
    private authService: AuthService,
    private backendService: BackendService,
    private offlineService: OfflineService,
    private synchronizationService: SynchronizationService,
    private successToast: SuccessToastService,
    private translate: TranslateService,
    private scopeService: ScopeService
  ) {}

  public static isRelatedToPerimeter(task: Task, perimeter: Perimeter): boolean {
    return (
      task.perimeter === perimeter.id ||
      perimeter.sub_perimeters.some(subPerimeter => TasksService.isRelatedToPerimeter(task, subPerimeter))
    );
  }

  public mapTask(task: Task): Observable<MappedTask> {
    return combineLatest([this.offlineService.getConfig("users"), this.offlineService.getConfig("taskStates")])
      .pipe(
        map(([users, taskStates]) => ({
          ...task,
          created_by_object: users.find(user => user.get_user_id === task.created_by),
          assigned_object: users.find(user => user.get_user_id === task.assigned),
          state_object: taskStates.find(state => state.id === task.state),
          deadline_date: new Date(task.deadline),
          // Map investment if any
        }))
      )
      .pipe(
        switchMap(mappedTask => {
          if (task.asset) {
            return this.offlineService.getAsset(task.asset).pipe(
              map(asset => ({
                ...mappedTask,
                asset_object: asset,
              }))
            );
          } else if (task.investment) {
            return this.offlineService.getInvestment(task.investment).pipe(
              map(investment => ({
                ...mappedTask,
                investment_object: investment,
              }))
            );
          } else {
            return of(mappedTask);
          }
        })
      );
  }

  public getAssignedTasksPerimeter(perimeter: Perimeter, refresh: boolean = false): Observable<Task[]> {
    return this.getAssignedTasksPerimeters([perimeter], refresh).pipe(
      map(tasksMap => {
        return tasksMap[perimeter.id];
      })
    );
  }

  public getAssignedTasksPerimeters(
    perimeters: Perimeter[],
    refresh: boolean = false
  ): Observable<{ [perimeter: number]: Task[] }> {
    return this.getAssignedTasks(refresh).pipe(
      map(tasks => {
        const tasksMap = {};
        perimeters.forEach(perimeter => {
          const perimeterTasks = [];
          tasks.forEach(task => {
            if (task.perimeter && TasksService.isRelatedToPerimeter(task, perimeter)) {
              perimeterTasks.push(task);
            }
          });
          tasksMap[perimeter.id] = perimeterTasks;
        });
        return tasksMap;
      })
    );
  }

  public getAssignedTasks(refresh: boolean = false): Observable<Task[]> {
    if (refresh) {
      return (
        this.getAssignedTasksFromAPI()
          .pipe(map(tasks => tasks))
          // On error, fallback to cache
          .pipe(catchError(() => this.getAssignedTasksFromCache()))
      );
    } else {
      return (
        this.getAssignedTasksFromCache()
          .pipe(map(tasks => tasks))
          // On error, retrieve from API
          .pipe(catchError(() => this.getAssignedTasksFromAPI()))
      );
    }
  }

  public acknowledgeTask(task: Task): Observable<Task> {
    const updatedTask: Task = {
      ...task,
      state: TaskState.STATE_DONE,
    };

    return this.updateTask(updatedTask);
  }

  public unAcknowledgeTask(task: Task): Observable<Task> {
    const updatedTask: Task = {
      ...task,
      state: TaskState.STATE_PENDING,
    };

    return this.updateTask(updatedTask);
  }

  public initNewTask(perimeter?: number, asset?: number, investment?: number): Observable<Partial<Task>> {
    return from(this.authService.getCurrentUser()).pipe(
      map(user => ({
        created_by: user.get_user_id,
        deadline: this.getDefaultDeadline(),
        state: TaskState.STATE_PENDING,
        perimeter,
        asset,
        investment,
      }))
    );
  }

  private getDefaultDeadline(): string {
    const currentYear = new Date().getFullYear();
    const defaultYear = currentYear + 1;
    return defaultYear + "-01-01";
  }

  public createTask(task: Partial<Task>): Observable<Task> {
    const taskToCreate = <Task>{
      ...task,
      id: 0,
      local_id: getLocalId(),
    };

    const change = makeChange(ChangeAction.addTaskAction, `/tasks/api/tasks/`, "post", taskToCreate);

    return (
      this.synchronizationService
        .addChange(change)
        .pipe(switchMap(() => this.synchronizationService.signalOfflineChanges()))
        .pipe(switchMap(() => this.authService.getCurrentUser()))
        // Add this new task to assigned tasks list if it's assigned to the current user
        .pipe(
          switchMap(user => {
            this.successToast.showObjectCreated(0);
            if (taskToCreate.assigned === user.get_user_id) {
              return this.getAssignedTasksFromCache().pipe(
                switchMap(tasks => {
                  tasks.push(taskToCreate);
                  return this.storeAssignedTasks(tasks);
                })
              );
            } else {
              return of(null);
            }
          })
        )
        .pipe(map(() => taskToCreate))
    );
  }

  public updateTask(task: Task): Observable<Task> {
    const change = makeChange(ChangeAction.saveTaskAction, `/tasks/api/tasks/${task.id}/`, "patch", task);

    return this.synchronizationService
      .addChange(change)
      .pipe(switchMap(() => this.synchronizationService.signalOfflineChanges()))
      .pipe(switchMap(() => this.updateTaskInAssignedTasksCache(task)))
      .pipe(map(() => task));
  }

  public deleteTask(task: Task): Observable<void> {
    const change = makeChange(ChangeAction.saveTaskAction, `/tasks/api/tasks/${task.id}/`, "delete", task);

    return (
      this.synchronizationService
        .addChange(change)
        .pipe(switchMap(() => this.synchronizationService.signalOfflineChanges()))
        // Remove this task from assigned tasks
        .pipe(
          switchMap(() => {
            return this.getAssignedTasksFromCache().pipe(
              switchMap(assignedTasks => {
                const tasks = assignedTasks.filter(
                  t => (task.local_id && t.local_id !== task.local_id) || (task.id && t.id !== task.id)
                );
                return this.storeAssignedTasks(tasks);
              })
            );
          })
        )
    );
  }

  public updateTaskInAssignedTasksCache(task: Task): Observable<void> {
    return this.getAssignedTasksFromCache().pipe(
      switchMap(tasks => {
        const taskIndex = tasks.findIndex(
          t => (task.local_id && t.local_id === task.local_id) || (task.id && t.id === task.id)
        );
        if (taskIndex >= 0) {
          tasks[taskIndex] = task;
        }
        return this.storeAssignedTasks(tasks);
      })
    );
  }

  public createReminderTaskForInvestment(
    investment: Investment,
    docRequirements: DocumentRequirement[],
    userId: number
  ): Observable<Task> {
    return new Observable(observer => {
      let documentType = "";
      for (let i = 0; i < docRequirements.length; i++) {
        if (docRequirements[i].suggestAutomaticReminder) {
          documentType = docRequirements[i].document_type.label;
          break;
        }
      }
      this.translate
        .get("Reminder: {{documentType}} to produce for investment {{label}}", {
          documentType: documentType,
          label: investment.label,
        })
        .subscribe((taskLabel: string) => {
          const task = <Task>{
            investment: investment.id,
            assigned: userId,
            deadline: moment(new Date(investment.initialSchedule - 1, 0, 1)).format("YYYY-MM-DD"),
            label: taskLabel,
          };

          this.createTask(task).subscribe(task => {
            observer.next(task);
            observer.complete();
          });
        });
    });
  }

  private getAssignedTasksFromCache(): Observable<Task[]> {
    return this.offlineService.getAssignedTasks();
  }

  private getAssignedTasksFromAPI(): Observable<Task[]> {
    return this.scopeService.currentMultiPerimeter
      .pipe(
        switchMap(site =>
          this.backendService.get("/tasks/api/tasks/", {
            site: site.id,
            size: 500,
          })
        )
      )
      .pipe(map(result => result.results as Task[]))
      .pipe(tap(tasks => this.storeAssignedTasks(tasks).subscribe()));
  }

  private storeAssignedTasks(tasks: Task[]): Observable<void> {
    return this.offlineService.storeAssignedTasks(tasks);
  }
}
