import * as R from "ramda";
import { User } from "./auth";
import { AppUser, ImportableMixin, Picture } from "./base";
import { makeInvestment, Investment, IBudgetScope } from "./investments";

export const ASSET_STATUS_VALIDATED = 1;
export const ASSET_STATUS_WAITING_FOR_VALIDATION = 2;

export const ASSET_STEP_ACTIVE = 1;
export const ASSET_STEP_ARCHIVED = 2;
export const ASSET_STEP_FUTURE = 3;

export enum MissingNotationReason {
  NOT_SET = 0,
  STOPPED_ABNORMAL = 1,
  STOPPED_INSTRUCTION = 2,
  BROKEN = 3,
  ACCESS = 4,
  WAITING_EVALUATION = 5,
  OTHER = 6,
  MANAGER_UNAVAILABLE = 7,
  MANAGER_DOESNT_KNOW = 8,
}

export enum YesNoIdk {
  NO = 0,
  YES = 1,
  IDONTKNOW = 2,
}

export class NotationMissingComment {
  constructor(public id: number, public notation_missing_reason: number, public default_comment: string) {}
}

/* AssetFloor model */
export class AssetFloor {
  constructor(public id: number, public name: string, public ordering: number, public only_for_clusters?: number[]) {}
}

export enum AssetTypeLevel {
  LEVEL_NONE = 0,
  LEVEL_COLLECTION = 1,
  LEVEL_UNIT = 2,
  LEVEL_COMPONENT = 3,
  LEVEL_COLLECTION_ITEM = 4,
}

export class Status {
  constructor(public value: number, public name: string) {}
}

export class Year {
  constructor(public value: number, public name: string) {}
}

export class Zone {
  constructor(
    public id: number,
    public name: string,
    public onlyForClusters: number[],
    public setupReference: string
  ) {}
}

export class PowerSource {
  constructor(public id: number, public name: string) {}
}

export class RefrigerantType {
  constructor(public id: number, public name: string) {}
}

export class ThermalResistanceCoefficientR {
  constructor(public id: number, public name: string) {}
}

export class HeatTransferCoefficientU {
  constructor(public id: number, public name: string) {}
}

export class SourceInfoThermalResistanceCoefficientR {
  constructor(public id: number, public name: string) {}
}

export class SourceInfoHeatTransferCoefficientU {
  constructor(public id: number, public name: string) {}
}

export const getAvailableZones = (zones: Zone[], cluster: number): Zone[] => {
  if (!cluster) {
    return zones;
  }

  return zones.filter(zone => {
    return zone.onlyForClusters.length === 0 || R.includes(cluster, zone.onlyForClusters);
  });
};

export class OwnershipType {
  constructor(public id: number, public name: string, public order: number) {}
}

export class NewSiteReplacementStrategy {
  constructor(public id: number, public name: string, public is_default: boolean) {}
}

export class AssetConfidenceChoice {
  constructor(public id: number, public name: string) {}
}

export class QuantityUnit {
  constructor(public id: number, public name: string, public is_default: boolean) {}
}

export class MeasurementUnits {
  constructor(public id: number, public name: string) {}
}
export class BudgetReference {
  constructor(
    public budgetPrice: number,
    public hypothesis: string,
    public materialPrice: number,
    public additionalPrice: number,
    public workloads: Array<BudgetReferenceWorkload> = []
  ) {}
}

export class AssetTypeKeyword {
  constructor(public id: number, public name: string) {}
}

export class AssetType {
  constructor(
    public id: number,
    public name: string,
    public quantity_unit: QuantityUnit,
    public measurement_units: MeasurementUnits,
    public expected_duration: number,
    public questions: Array<number>,
    public onlyTheseZones: Array<number>,
    public onlyForClusters: number[],
    public defaultOwnershipType: OwnershipType,
    public defaultZone: Zone,
    public budgetReference: BudgetReference,
    public availableFrom: number = 0,
    public availableTo: number = 0,
    public brandSupport: boolean = false,
    public modelSupport: boolean = false,
    public powerSourceSupport: boolean = false,
    public powerSupport: boolean = false,
    public servedZoneSupport: boolean = false,
    public level: AssetTypeLevel = AssetTypeLevel.LEVEL_NONE,
    public show_floor: boolean = false,
    public onlyTheseFloors: number[] = [],
    public defaultFloor: AssetFloor = null,
    public keywords: AssetTypeKeyword[] = [],
    public default_quantity: number = 0,
    public default_height: number = 0,
    public plannedResale: boolean = false,
    public plannedRecycle: boolean = false,
    public heightSupport: boolean = false,
    public alphaCoefficient: number,
    public manage_latest_energy_yield_declaration: boolean = false,
    public showRefrigerantType: boolean = false,
    public default_latest_refrigerant_declaration: number = 0,
    public energy_type: number = null,
    public friendly_name: string,
    public showHeatTransferCoefficientU: boolean = false,
    public showThermalResistanceCoefficientR: boolean = false,
    public show_equipment_count: number = 0,
    public showMaintenanceCode: boolean = false,
    public is_energivore: boolean = false,
    public onlyForPerimeterTypes: number[] = []
  ) {}
}

export const getAvailableAssetTypes = (assetTypes: AssetType[], cluster: number, zone: number): AssetType[] => {
  let availableAssetTypes = assetTypes;

  if (cluster) {
    availableAssetTypes = availableAssetTypes.filter(assetType => {
      return assetType.onlyForClusters.length === 0 || R.includes(cluster, assetType.onlyForClusters);
    });
  }

  if (zone) {
    availableAssetTypes = availableAssetTypes.filter(assetType => {
      return assetType.onlyTheseZones.length === 0 || R.includes(zone, assetType.onlyTheseZones);
    });
  }

  return availableAssetTypes;
};

export class AssetPicture extends Picture {
  constructor(
    public id: number,
    public asset: number,
    public picture: string,
    public thumbnail: string,
    public user: number,
    public datetime: string,
    public localId: string,
    public author: string,
    public localPath?: string,
    public browserFile?: File
  ) {
    super(id, picture, thumbnail, user, datetime, localId, author, localPath, browserFile);
  }
}

export class SubCategory {
  constructor(
    public id: number,
    public name: string,
    public onlyForClusters: number[],
    public children: Array<AssetType>,
    public availableFrom: number = 0,
    public availableTo: number = 0
  ) {}
}

export const getAvailableSubCategories = (
  subCategories: SubCategory[],
  cluster: number,
  zone: number
): SubCategory[] => {
  const availableSubCategories: SubCategory[] = [];

  subCategories.forEach(subCategory => {
    subCategory.children = getAvailableAssetTypes(subCategory.children, cluster, zone);
    if (
      subCategory.children.length > 0 &&
      (subCategory.onlyForClusters.length === 0 || R.includes(cluster, subCategory.onlyForClusters))
    ) {
      availableSubCategories.push(subCategory);
    }
  });

  return availableSubCategories;
};

export interface SuperCategory {
  id: number;
  name: string;
  children: Category[];
}

export class Category {
  constructor(
    public id: number,
    public name: string,
    public onlyForClusters: number[],
    public children: Array<SubCategory>,
    public availableFrom: number = 0,
    public availableTo: number = 0,
    public parent: number // super-category id
  ) {}
}

export const getAvailableCategories = (categories: Category[], cluster: number, zone: number): Category[] => {
  const availableCategories: Category[] = [];

  categories.forEach(category => {
    category.children = getAvailableSubCategories(category.children, cluster, zone);
    if (
      category.children.length > 0 &&
      (category.onlyForClusters.length === 0 || R.includes(cluster, category.onlyForClusters))
    ) {
      availableCategories.push(category);
    }
  });

  return availableCategories;
};

export class Building {
  constructor(
    public id: number,
    public name: string,
    public constructionYear: number,
    public siteName: string,
    public monosite_perimeter: Perimeter,
    public flooring_area?: number
  ) {}
}

export class BudgetReferenceWorkload {
  constructor(public hourlyRate: number, public hoursNumber: number) {}
}

export enum ImportableAssetStatus {
  NOTATION = "NOTATION",
  INSTALLATION_YEAR = "INSTALLATION_YEAR",
  READY = "READY",
  VALIDATED = "VALIDATED",
}

export class ParentAsset {
  constructor(public id: number, public offline: boolean, public offlineId: number) {}
}

export class MinimalAsset {
  constructor(public id: number, public label: string, public assetType: AssetType, public step: number) {}
}

export class Asset implements ImportableMixin<ImportableAssetStatus> {
  constructor(
    public id: number,
    public building: Building,
    public category: Category,
    public subCategory: SubCategory,
    public assetType: AssetType,
    public zone: Zone,
    public zoneDetails: string,
    public ratingReasons: number[],
    public installationYear: number,
    public quantity: number,
    public durationDeviation: number,
    public expectedLifetime: number,
    public status: number,
    public ownershipType: OwnershipType,
    public pictures: AssetPicture[],
    public perimeters: number[],
    public offline: boolean,
    public offlineId: number,
    public deleted: boolean,
    public notes: any,
    public investments: Investment[],
    public comments: string,
    public budgetReference: BudgetReference,
    public showMaintenanceCode: boolean,
    public maintenanceCode: string,
    public label: string,
    public qrcode: string,
    public barcode: string,
    public brand: string,
    public model: string,
    public powerSource: number,
    public power: string,
    public servedZone: string,
    public notationMissingReason: MissingNotationReason,
    public notationMissingComment: string,
    public parent: ParentAsset | null,
    public children: Asset[],
    public newSiteReplacementStrategy: NewSiteReplacementStrategy,
    public plannedResale: YesNoIdk,
    public plannedRecycle: YesNoIdk,
    public resaleValue: number,
    public recycleValue: number,
    public height: number,
    public latestRefrigerantDeclaration: number,
    public refrigerantType: number,
    public latest_energy_yield_declaration: number,
    public importedAt?: Date,
    public importSource?: string,
    public importReference?: string,
    public importStatuses?: ImportableAssetStatus[],
    public expertMode: any = {}, // map { userId: True/False (True if expert mode is active for this user }
    public technical_state_changed_by?: number,
    public technical_state_changed_on?: Date,
    public level: string = "",
    public lastAccess: Date = null,
    public floor: AssetFloor = null,
    public notesComment: Map<number, String> = null,
    public notesPictures: Map<number, NotationQuestionPicture[]> = null,
    // (Removed from migration) levelFromExternalBase is used for CMMS Synchronization. Currently filled with fake data for the
    // Post-Immo POC (US:3820). Remember to change this when the actual functionnality will be developed
    public levelFromExternalBase: string = importStatuses && importStatuses.length > 0 && level ? level : null,
    public heatTransferCoefficientU?: number,
    public thermalResistanceCoefficientR?: number,
    public sourceInfoHeatTransferCoefficientU?: number,
    public sourceInfoThermalResistanceCoefficientR?: number,
    public equipment_count?: number,
    public step: number = ASSET_STEP_ACTIVE,
    public replacedBy: MinimalAsset | null = null,
    public replacementOf: MinimalAsset | null = null
  ) {}
}

export function getAssetBuildingId(asset: Asset): number {
  // getting the BuildingId of an asset may be complex
  if (asset.building.id) {
    return asset.building.id;
  } else {
    if (asset.building.monosite_perimeter.building) {
      return asset.building.monosite_perimeter.building.id;
    } else {
      return asset.building.monosite_perimeter.building_id;
    }
  }
}

/**
 * create an asset picture from data received from the backend
 */
export function makeAssetPicture(jsonData: any): AssetPicture {
  return new AssetPicture(
    jsonData.id,
    jsonData.asset,
    jsonData.picture,
    jsonData.thumbnail,
    jsonData.user,
    jsonData.datetime,
    jsonData.local_id,
    jsonData.author
  );
}

export function makeNotationQuestionPicture(jsonData: any): NotationQuestionPicture {
  return new NotationQuestionPicture(
    jsonData.id,
    jsonData.asset,
    jsonData.question_item,
    jsonData.picture,
    jsonData.thumbnail,
    jsonData.user,
    jsonData.datetime,
    jsonData.local_id,
    jsonData.author
  );
}

export function makeBudgetReference(jsonData: any): BudgetReference {
  if (jsonData) {
    let reference: BudgetReference = new BudgetReference(
      Number(jsonData.budget_price),
      jsonData.hypothesis,
      Number(jsonData.material_price),
      Number(jsonData.additional_price)
    );
    for (let i = 0; i < jsonData.workloads.length; i++) {
      reference.workloads.push(
        new BudgetReferenceWorkload(
          Number(jsonData.workloads[i].hourly_rate),
          Number(jsonData.workloads[i].hours_number)
        )
      );
    }
    return reference;
  }
  return null;
}

/**
 * create zone
 */
export function makeZone(jsonData: any): Zone {
  return new Zone(jsonData.id, jsonData.name, jsonData.only_for_clusters, jsonData.setup_reference);
}

/**
 * create object
 */
export function makeNewSiteReplacementStrategy(jsonData: any): NewSiteReplacementStrategy {
  return new NewSiteReplacementStrategy(jsonData.id, jsonData.name, jsonData.is_default);
}

export function makeAssetTypeKeyword(jsonData: any): AssetTypeKeyword {
  return new AssetTypeKeyword(jsonData.id, jsonData.name);
}

/**
 * create asset types
 */
export function makeAssetType(jsonData: any): AssetType {
  let quantityUnit = null;
  if (jsonData.quantity_unit) {
    quantityUnit = new QuantityUnit(
      jsonData.quantity_unit.id,
      jsonData.quantity_unit.name,
      jsonData.is_default || false
    );
  }

  let measurementUnits = null;
  if (jsonData.measurement_units) {
    measurementUnits = new MeasurementUnits(jsonData.measurement_units.id, jsonData.measurement_units.name);
  }

  let onlyTheseZones: number[] = [];
  if (jsonData.only_these_zones && jsonData.only_these_zones.length) {
    onlyTheseZones = jsonData.only_these_zones;
  }

  let onlyForClusters: number[] = [];
  if (jsonData.only_for_clusters && jsonData.only_for_clusters.length) {
    onlyForClusters = jsonData.only_for_clusters;
  }

  let onlyForPerimeterTypes: number[] = [];
  if (jsonData.only_for_perimeter_types?.length) {
    onlyForPerimeterTypes = jsonData.only_for_perimeter_types;
  }

  let defaultOwnershipType: OwnershipType = null;
  if (jsonData.default_ownership_type) {
    defaultOwnershipType = new OwnershipType(
      jsonData.default_ownership_type.id,
      jsonData.default_ownership_type.name,
      jsonData.default_ownership_type.order
    );
  }

  let defaultZone: Zone = null;
  if (jsonData.default_zone) {
    defaultZone = makeZone(jsonData.default_zone);
  }

  let keywords: AssetTypeKeyword[] = [];
  if (jsonData.keywords) {
    keywords = jsonData.keywords.map(elt => makeAssetTypeKeyword(elt));
  }

  return new AssetType(
    jsonData.id,
    jsonData.name,
    quantityUnit,
    measurementUnits,
    jsonData.expected_duration,
    jsonData.questions,
    onlyTheseZones,
    onlyForClusters,
    defaultOwnershipType,
    defaultZone,
    makeBudgetReference(jsonData.budget_reference_data),
    jsonData.available_from || 0,
    jsonData.available_to || 0,
    jsonData.brand_support || null,
    jsonData.model_support || null,
    jsonData.power_source_support || null,
    jsonData.power_support || null,
    jsonData.served_zone_support || null,
    jsonData.level || AssetTypeLevel.LEVEL_NONE,
    jsonData.show_floor,
    jsonData.only_these_floors,
    jsonData.default_floor,
    keywords,
    jsonData.default_quantity,
    jsonData.default_height || null,
    jsonData.planned_resale,
    jsonData.planned_recycle,
    jsonData.height_support,
    jsonData.alpha_coefficient,
    jsonData.manage_latest_energy_yield_declaration,
    jsonData.show_refrigerant_type || null,
    jsonData.default_latest_refrigerant_declaration || null,
    jsonData.energy_type,
    jsonData.friendly_name,
    jsonData.show_heat_transfer_coefficient_u,
    jsonData.show_thermal_resistance_coefficient_r,
    jsonData.show_equipment_count,
    jsonData.show_maintenance_code,
    jsonData.is_energivore,
    onlyForPerimeterTypes
  );
}

/**
 * create sub-categories
 */
export function makeSubCategory(jsonData: any): SubCategory {
  let children: Array<AssetType> = [];
  if (jsonData.children) {
    for (let i = 0; i < jsonData.children.length; i++) {
      children.push(makeAssetType(jsonData.children[i]));
    }
  }

  return new SubCategory(
    jsonData.id,
    jsonData.name,
    jsonData.only_for_clusters,
    children,
    jsonData.available_from || 0,
    jsonData.available_to || 0
  );
}

/**
 * create category
 */
export function makeCategory(jsonData: any): Category {
  let children: Array<SubCategory> = [];
  if (jsonData.children) {
    for (let i = 0; i < jsonData.children.length; i++) {
      children.push(makeSubCategory(jsonData.children[i]));
    }
  }

  return new Category(
    jsonData.id,
    jsonData.name,
    jsonData.only_for_clusters,
    children,
    jsonData.available_from || 0,
    jsonData.available_to || 0,
    jsonData.parent
  );
}

/**
 * create sub-categories
 */
export function makeAssetSubCategory(jsonData: any): SubCategory {
  return new SubCategory(jsonData.id, jsonData.name, [], jsonData.available_from || 0, jsonData.available_to || 0);
}

/**
 * create category
 */
export function makeAssetCategory(jsonData: any): Category {
  return new Category(
    jsonData.id,
    jsonData.name,
    [],
    [],
    jsonData.available_from || 0,
    jsonData.available_to || 0,
    jsonData.parent
  );
}

export function makeBuilding(jsonBuilding): Building {
  return new Building(
    jsonBuilding.id,
    jsonBuilding.name || "",
    jsonBuilding.construction_year || 0,
    jsonBuilding.site_name || "",
    jsonBuilding.monosite_perimeter ? makePerimeter(jsonBuilding.monosite_perimeter) : null,
    jsonBuilding.flooring_area
  );
}

/**
 * create power source
 */
export function makePowerSource(jsonData: any): PowerSource {
  return new PowerSource(jsonData.id, jsonData.name);
}

export function makeRefrigerantType(jsonData: any): RefrigerantType {
  return new RefrigerantType(jsonData.id, jsonData.name);
}

export function makeThermalResistanceCoefficientR(jsonData: any): ThermalResistanceCoefficientR {
  return new ThermalResistanceCoefficientR(jsonData.id, jsonData.name);
}

export function makeHeatTransferCoefficientU(jsonData: any): HeatTransferCoefficientU {
  return new HeatTransferCoefficientU(jsonData.id, jsonData.name);
}

export function makeSourceInfoThermalResistanceCoefficientR(jsonData: any): SourceInfoThermalResistanceCoefficientR {
  return new SourceInfoThermalResistanceCoefficientR(jsonData.id, jsonData.name);
}

export function makeSourceInfoHeatTransferCoefficientU(jsonData: any): SourceInfoHeatTransferCoefficientU {
  return new SourceInfoHeatTransferCoefficientU(jsonData.id, jsonData.name);
}

export function makeParentAsset(jsonData: any): ParentAsset {
  return new ParentAsset(jsonData.id, jsonData.id === 0, jsonData.offlineId || 0);
}

export function makeAssetFloor(jsonData: any): AssetFloor {
  return new AssetFloor(jsonData.id, jsonData.name, jsonData.ordering, jsonData.only_for_clusters);
}

export function makeMinimalAsset(jsonData: any): MinimalAsset | null {
  if (jsonData) {
    return new MinimalAsset(
      jsonData.id,
      jsonData.label,
      jsonData.asset_type ? makeAssetType(jsonData.asset_type) : makeAssetType(jsonData.assetType),
      jsonData.step
    );
  }
  return null;
}

/**
 * create an asset from data received from the backend
 */
export function makeAsset(jsonData: any): Asset {
  let ownershipType: OwnershipType = null;
  if (jsonData.ownership_type) {
    if (typeof jsonData.ownership_type === "number") {
      ownershipType = jsonData.ownership_type;
    } else {
      ownershipType = new OwnershipType(
        jsonData.ownership_type.id,
        jsonData.ownership_type.name,
        jsonData.ownership_type.order
      );
    }
  }

  let assetType: AssetType = makeAssetType(jsonData.asset_type);
  let building: Building = makeBuilding(jsonData.building);

  let notes: any = {};
  if (jsonData.notes) {
    notes = jsonData.notes;
  }

  let offlineId: number = 0;
  if (jsonData.offlineId) {
    offlineId = jsonData.offlineId;
  }

  let assetPictures: Array<AssetPicture> = [];
  if (jsonData.pictures) {
    for (let i = 0; i < jsonData.pictures.length; i++) {
      let picture: AssetPicture = makeAssetPicture(jsonData.pictures[i]);
      assetPictures.push(picture);
    }
  }

  let investments: Array<Investment> = [];
  if (jsonData.investments) {
    for (let i = 0; i < jsonData.investments.length; i++) {
      let investment: Investment = makeInvestment(jsonData.investments[i]);
      investments.push(investment);
    }
  }

  let budgetReference = null;
  if (jsonData.budget_reference_data) {
    budgetReference = makeBudgetReference(jsonData.budget_reference_data);
  }

  let newSiteReplacementStrategy = null;
  if (jsonData.new_site_replacement_strategy) {
    newSiteReplacementStrategy = makeNewSiteReplacementStrategy(jsonData.new_site_replacement_strategy);
  }

  let children = [];
  if (jsonData.children) {
    children = jsonData.children;
  }

  const notationPictures: any = {};
  if (jsonData.notes_pictures) {
    for (let key of Object.keys(jsonData.notes_pictures)) {
      let jsonPictures = jsonData.notes_pictures[key];
      notationPictures[key] = jsonPictures.map(elt => makeNotationQuestionPicture(elt));
    }
  }

  return new Asset(
    jsonData.id,
    building,
    makeAssetCategory(jsonData.category),
    makeAssetSubCategory(jsonData.sub_category),
    assetType,
    jsonData.zone,
    jsonData.zone_details,
    jsonData.ratingReasons,
    jsonData.installation_year,
    Number(jsonData.quantity),
    jsonData.duration_deviation,
    jsonData.expected_lifetime,
    jsonData.status,
    ownershipType,
    assetPictures,
    jsonData.perimeters,
    !!jsonData.offline,
    offlineId,
    false,
    notes,
    investments,
    jsonData.comments,
    budgetReference,
    jsonData.show_maintenance_code || false,
    jsonData.maintenance_code || "",
    jsonData.label,
    jsonData.qrcode,
    jsonData.barcode || "",
    jsonData.brand || null,
    jsonData.model || null,
    jsonData.power_source,
    jsonData.power || null,
    jsonData.served_zone,
    jsonData.notation_missing_reason || MissingNotationReason.NOT_SET,
    jsonData.notation_missing_comment || "",
    jsonData.parent ? makeParentAsset(jsonData.parent) : null,
    children.map(elt => makeAsset(elt)),
    newSiteReplacementStrategy,
    jsonData.planned_resale || YesNoIdk.IDONTKNOW,
    jsonData.planned_recycle || YesNoIdk.IDONTKNOW,
    jsonData.resale_value || null,
    jsonData.recycle_value || null,
    jsonData.height || null,
    jsonData.latest_refrigerant_declaration || null,
    jsonData.refrigerant_type,
    jsonData.latest_energy_yield_declaration || null,
    jsonData.imported_at ? new Date(jsonData.imported_at) : null,
    jsonData.import_source || null,
    jsonData.import_reference || null,
    jsonData.import_statuses || null,
    jsonData.expert_mode || {},
    jsonData.technical_state_changed_by || null,
    jsonData.technical_state_changed_on || null,
    jsonData.level || "",
    jsonData.last_access ? new Date(jsonData.last_access) : null,
    jsonData.floor ? makeAssetFloor(jsonData.floor) : null,
    jsonData.notes_comment || {},
    notationPictures,
    jsonData.levelFromExternalBase,
    jsonData.heat_transfer_coefficient_u || null,
    jsonData.thermal_resistance_coefficient_r || null,
    jsonData.source_info_heat_transfer_coefficient_u || null,
    jsonData.source_info_thermal_resistance_coefficient_r || null,
    jsonData.equipment_count || null,
    jsonData.step || ASSET_STEP_ACTIVE,
    makeMinimalAsset(jsonData.replaced_by),
    makeMinimalAsset(jsonData.replacement_of)
  );
}

export function makeEmptyAsset(): Asset {
  return new Asset(
    null, //id
    null, //building
    null, //category
    null, //sub category
    null, //asset type
    null, //zone
    null, //zone details
    [], //rating reasons
    null, //installation year
    0, //quantity
    0, //duration deviation
    0, //expected lifetime
    null, //status
    null, //ownership type
    [], // asset pictures
    [], //perimeters
    false, //offline
    0, //offline id
    false, //deleted?
    {}, //notes
    [], //investments
    "", //comments
    null, //budget reference
    false, //show maintenance code
    "", //maintenance code
    "", //label
    "", //qr code
    "", //barcode
    null, //brand
    null, //model
    null, //power source
    null, //power
    null, //served zone
    MissingNotationReason.NOT_SET, //missing notation reason
    "", //notation missing comment
    null, //parent
    [], //children
    null, //new site replacement strategy
    YesNoIdk.NO, //planned resale?
    YesNoIdk.NO, // planned recycle?
    null, //resale value
    null, //recycle value
    null, //height
    null, //latest refrigeration declaration
    null, //refrigerant type
    null, //latest energy yield declaration
    null, //imported date
    null, //import source
    null, //import reference
    null, //import statuses
    {}, //expert mode
    null, //tech state changed by
    null, //tech state changed date
    "", //level
    null, // last access date
    null, //floor
    null, //notes comment
    null, // notes pictures
    null, // level from external DB
    null, //heat transfer
    null, // thermal resistance
    null, // source info U
    null, // source info R
    null, //number equipment
    ASSET_STEP_ACTIVE
  );
}

export function makeAddAssetData(monoPerimeter: Perimeter, asset: Asset, offlineId: number): any {
  const building = {
    ...monoPerimeter.building,
    monosite_perimeter: monoPerimeter,
  };
  let assetData: any = {
    id: 0,
    perimeter: monoPerimeter,
    zone: asset.zone,
    category: asset.category,
    sub_category: asset.subCategory,
    asset_type: asset.assetType,
    installation_year: asset.installationYear,
    zone_details: asset.zoneDetails || "",
    label: asset.label,
    quantity: asset.quantity || 1,
    pictures_count: 0,
    duration_deviation: asset.durationDeviation,
    perimeters: [monoPerimeter.id],
    offline: true,
    status: ASSET_STATUS_WAITING_FOR_VALIDATION,
    building: building,
    offlineId: offlineId,
    ownership_type: !asset.ownershipType
      ? null
      : typeof asset.ownershipType === "number"
      ? asset.ownershipType
      : asset.ownershipType.id,
    comments: asset.comments || "",
    qrcode: asset.qrcode,
    brand: asset.brand,
    model: asset.model,
    powerSource: asset.powerSource,
    power: asset.power,
    servedZone: asset.servedZone,
    new_site_replacement_strategy: asset.newSiteReplacementStrategy,
    notation_missing_reason: asset.notationMissingReason,
    notation_missing_comment: asset.notationMissingComment,
    technical_state_changed_by: asset.technical_state_changed_by,
    technical_state_changed_on: asset.technical_state_changed_on,
    floor: asset.floor,
    recycle_value: asset.recycleValue,
    resale_value: asset.resaleValue,
    planned_recycle: asset.plannedRecycle,
    planned_resale: asset.plannedResale,
    height: asset.height,
    expert_mode: asset.expertMode,
    latest_refrigerant_declaration: asset.latestRefrigerantDeclaration,
    refrigerant_type: asset.refrigerantType,
    latest_energy_yield_declaration: asset.latest_energy_yield_declaration,
    heat_transfer_coefficient_u: asset.heatTransferCoefficientU,
    thermal_resistance_coefficient_r: asset.thermalResistanceCoefficientR,
    source_info_heat_transfer_coefficient_u: asset.sourceInfoHeatTransferCoefficientU,
    source_info_thermal_resistance_coefficient_r: asset.sourceInfoThermalResistanceCoefficientR,
    equipment_count: asset.equipment_count,
    notes_pictures: asset.notesPictures,
    maintenance_code: asset.maintenanceCode,
    step: asset.step,
  };
  return assetData;
}

/**
 * Make sure that the asset is still compatible for every version
 * @param {Asset} asset
 * @returns {Asset}
 */
export function setAsset(asset: Asset): Asset {
  if (asset.investments) {
    for (let i = 0; i < asset.investments.length; i++) {
      if (!asset.investments[i].reasons) {
        asset.investments[i].reasons = [];
      }
    }
  } else {
    asset.investments = [];
  }
  let investments = asset.investments;
  asset.investments = [];
  for (let i = 0; i < investments.length; i++) {
    asset.investments.push(Object.assign(new Investment(), investments[i]));
  }
  return asset;
}

/**
 * patchAsset : update an asset with data used for patch
 * @param asset : the original asset
 * @param patchData : the data to patch
 * @returns {Asset} : the patched asset
 */

export function patchAsset(asset: Asset, patchData: any): Asset {
  for (let elt in patchData) {
    asset[elt] = patchData[elt];
  }
  return asset;
}

/**
 *
 * @param perimeter
 * @param data
 * @returns {{perimeter: number, zone: number, asset_type: number, installation_year: number, zone_details: string, quantity: number}}
 */
export function getDataForAddAsset(perimeter: Perimeter, data: any): any {
  return {
    perimeter: perimeter.id,
    zone: data.zone?.id || null,
    asset_type: data.assetType.id,
    installation_year: data.installationYear,
    zone_details: data.zoneDetails || "",
    quantity: data.quantity || 1,
    label: data.label,
    comments: data.comments,
    duration_deviation: data.durationDeviation,
    brand: data.brand,
    model: data.model,
    power_source: data.powerSource,
    power: data.power,
    served_zone: data.servedZone,
    parent: data.parent ? data.parent.id : null,
    ownership_type: data.ownershipType,
    notation_missing_reason: data.notationMissingReason,
    notation_missing_comment: data.notationMissingComment,
    floor: data.floor ? data.floor.id : null,
    planned_resale: data.plannedResale,
    planned_recycle: data.plannedRecycle,
    recycle_value: data.recycleValue,
    resale_value: data.resaleValue,
    height: data.height,
    expert_mode: data.expertMode,
    refrigerant_type: data.refrigerantType,
    latest_refrigerant_declaration: data.latestRefrigerantDeclaration,
    latest_energy_yield_declaration: data.latest_energy_yield_declaration,
    heat_transfer_coefficient_u: data.heatTransferCoefficientU,
    thermal_resistance_coefficient_r: data.thermalResistanceCoefficientR,
    source_info_heat_transfer_coefficient_u: data.sourceInfoHeatTransferCoefficientU,
    source_info_thermal_resistance_coefficient_r: data.sourceInfoThermalResistanceCoefficientR,
    equipment_count: data.equipment_count,
    notes_pictures: data.notes_pictures,
    maintenance_code: data.maintenanceCode,
    step: data.step,
  };
}

/**
 * Get the data for performing a patch of the asset on the backend
 * @param patchData
 * @returns {any}
 */
export function getDataForPatchAsset(patchData: any): any {
  let dataForPatch = {};

  if (patchData.hasOwnProperty("zoneDetails")) {
    dataForPatch["zone_details"] = patchData.zoneDetails;
  }

  if (patchData.hasOwnProperty("label")) {
    dataForPatch["label"] = patchData.label;
  }

  if (patchData.hasOwnProperty("qrcode")) {
    dataForPatch["qrcode"] = patchData.qrcode;
  }

  if (patchData.hasOwnProperty("barcode")) {
    dataForPatch["barcode"] = patchData.barcode;
  }

  if (patchData.hasOwnProperty("brand")) {
    dataForPatch["brand"] = patchData.brand;
  }

  if (patchData.hasOwnProperty("model")) {
    dataForPatch["model"] = patchData.model;
  }

  if (patchData.hasOwnProperty("powerSource")) {
    dataForPatch["power_source"] = patchData.powerSource;
  }

  if (patchData.hasOwnProperty("power")) {
    dataForPatch["power"] = patchData.power;
  }

  if (patchData.hasOwnProperty("servedZone")) {
    dataForPatch["served_zone"] = patchData.servedZone;
  }

  if (patchData.hasOwnProperty("durationDeviation")) {
    dataForPatch["duration_deviation"] = patchData.durationDeviation;
  }

  if (patchData.hasOwnProperty("installationYear")) {
    dataForPatch["installation_year"] = patchData.installationYear;
  }

  if (patchData.hasOwnProperty("ownershipType")) {
    dataForPatch["ownership_type"] = patchData.ownershipType.id;
  }

  if (patchData.hasOwnProperty("quantity")) {
    dataForPatch["quantity"] = patchData.quantity;
  }

  if (patchData.hasOwnProperty("status")) {
    dataForPatch["status"] = patchData.status;
  }

  if (patchData.hasOwnProperty("step")) {
    dataForPatch["step"] = patchData.step;
  }

  if (patchData.hasOwnProperty("assetType")) {
    dataForPatch["asset_type"] = patchData.assetType.id;
  }

  if (patchData.hasOwnProperty("zone")) {
    dataForPatch["zone"] = patchData.zone.id;
  }

  if (patchData.hasOwnProperty("comments")) {
    dataForPatch["comments"] = patchData.comments;
  }

  if (patchData.hasOwnProperty("maintenance_code")) {
    dataForPatch["maintenance_code"] = patchData.maintenance_code;
  }

  if (patchData.hasOwnProperty("notation_missing_reason")) {
    dataForPatch["notation_missing_reason"] = patchData.notation_missing_reason;
  }

  if (patchData.hasOwnProperty("notation_missing_comment")) {
    dataForPatch["notation_missing_comment"] = patchData.notation_missing_comment;
  }

  if (patchData.hasOwnProperty("deviationReason")) {
    dataForPatch["deviation_reason"] = patchData.deviationReason;
  }

  if (patchData.hasOwnProperty("building")) {
    dataForPatch["building"] = patchData.building.id;
  }

  if (patchData.hasOwnProperty("budgetReference")) {
    const { hypothesis, materialPrice, additionalPrice, workloads } = patchData["budgetReference"];

    dataForPatch["budget_reference_data"] = {
      hypothesis,
      material_price: materialPrice,
      additional_price: additionalPrice,
      workloads: workloads.map(workload => ({
        hourly_rate: workload.hourlyRate,
        hours_number: workload.hoursNumber,
      })),
    };
  }

  if (patchData.hasOwnProperty("importStatuses")) {
    dataForPatch["import_statuses"] = patchData.importStatuses;
  }

  if (patchData.hasOwnProperty("newSiteReplacementStrategy")) {
    dataForPatch["new_site_replacement_strategy"] = patchData.newSiteReplacementStrategy.id;
  }

  if (patchData.hasOwnProperty("floor")) {
    dataForPatch["floor"] = patchData.floor.id;
  }

  if (patchData.hasOwnProperty("height")) {
    dataForPatch["height"] = patchData.height;
  }

  if (patchData.hasOwnProperty("recycleValue")) {
    dataForPatch["recycle_value"] = patchData.recycleValue;
  }

  if (patchData.hasOwnProperty("resaleValue")) {
    dataForPatch["resale_value"] = patchData.resaleValue;
  }

  if (patchData.hasOwnProperty("latestRefrigerantDeclaration")) {
    dataForPatch["latest_refrigerant_declaration"] = patchData.latestRefrigerantDeclaration;
  }

  if (patchData.hasOwnProperty("refrigerantType")) {
    dataForPatch["refrigerant_type"] = patchData.refrigerantType;
  }

  if (patchData.hasOwnProperty("plannedRecycle")) {
    dataForPatch["planned_recycle"] = patchData.plannedRecycle;
  }

  if (patchData.hasOwnProperty("plannedResale")) {
    dataForPatch["planned_resale"] = patchData.plannedResale;
  }

  if (patchData.hasOwnProperty("latest_energy_yield_declaration")) {
    dataForPatch["latest_energy_yield_declaration"] = patchData.latest_energy_yield_declaration;
  }

  if (patchData.hasOwnProperty("heatTransferCoefficientU")) {
    dataForPatch["heat_transfer_coefficient_u"] = patchData.heatTransferCoefficientU;
  }

  if (patchData.hasOwnProperty("thermalResistanceCoefficientR")) {
    dataForPatch["thermal_resistance_coefficient_r"] = patchData.thermalResistanceCoefficientR;
  }

  if (patchData.hasOwnProperty("sourceInfoHeatTransferCoefficientU")) {
    dataForPatch["source_info_heat_transfer_coefficient_u"] = patchData.sourceInfoHeatTransferCoefficientU;
  }

  if (patchData.hasOwnProperty("sourceInfoThermalResistanceCoefficientR")) {
    dataForPatch["source_info_thermal_resistance_coefficient_r"] = patchData.sourceInfoThermalResistanceCoefficientR;
  }

  if (patchData.hasOwnProperty("equipment_count")) {
    dataForPatch["equipment_count"] = patchData.equipment_count;
  }

  if (patchData.hasOwnProperty("notesPictures")) {
    dataForPatch["notes_pictures"] = patchData.notesPictures;
  }

  return dataForPatch;
}

export class AssetTab {
  constructor(public tabName: string, public asset: Asset, public readOnly: boolean) {}
}

export class PerimeterReferenceData {
  constructor(
    public id: number,
    public perimeter: number,
    public field: number,
    public label: string,
    public varName: string,
    public year: number,
    public value: any,
    public filterValue: any,
    public aggregated: boolean,
    public source: string,
    public author: number,
    public date: string
  ) {}
}

export enum PerimeterTypeClass {
  CLASS_NONE = 0,
  CLASS_GLOBAL = 1,
  CLASS_BUILDING = 2,
  CLASS_OTHER = 3,
}

export class PerimeterType {
  constructor(
    public id: number,
    public name: string,
    public only_for_clusters: Array<number>,
    public has_flooring_area: boolean,
    public default_zone: Zone,
    public perimeterTypeClass: PerimeterTypeClass,
    public level: number,
    public default_floor: number = null,
    public automatic_asset_label_template: string = null,
    public order: number = 0
  ) {}

  public isBuilding(): boolean {
    return this.perimeterTypeClass === PerimeterTypeClass.CLASS_BUILDING;
  }
}

export enum PerimeterTypeClass {
  NONE,
  GLOBAL,
  BUILDING,
  OTHER,
}

export class Perimeter {
  public filtered_sub_perimeters: Perimeter[] = [];

  constructor(
    public id: number,
    public name: string,
    public is_monosite: boolean,
    public level: number,
    public read_only: boolean = false,
    public sub_perimeters: Perimeter[],
    public building_id: number,
    public cluster: number,
    public perimeterType: number,
    public localId: string,
    public building: Building,
    public refData: Array<PerimeterReferenceData>,
    public pictures: PerimeterPicture[] = [],
    public creationYear: number | null,
    public flooring_area: number,
    public level_parent: number,
    public level_parent_local_id: string,
    public installationDateConfidence: number,
    public code: string,
    public coordinates: string = "",
    public cover_image?: number | string,
    public site_notes?: string
  ) {}
}

export class PerimeterPicture extends Picture {
  constructor(
    public id: number,
    public perimeter: number,
    public picture: string,
    public thumbnail: string,
    public user: number,
    public datetime: string,
    public localId: string,
    public author: string,
    public localPath?: string,
    public browserFile?: File,
    public isUsedForReports?: boolean
  ) {
    super(id, picture, thumbnail, user, datetime, localId, author, localPath, browserFile);
  }
}

export class NotationQuestionPicture extends Picture {
  constructor(
    public id: number,
    public assetId: number,
    public questionItemId: number,
    public picture: string,
    public thumbnail: string,
    public user: number,
    public datetime: string,
    public localId: string,
    public author: string,
    public localPath?: string,
    public browserFile?: File
  ) {
    super(id, picture, thumbnail, user, datetime, localId, author, localPath, browserFile);
  }
}

/**
 * sortPerimeters : avoid to download all assets for clusters (many sites)
 * @param perimeters
 * @returns {Array<Perimeter>}: sorted array of perimeters
 */
export function sortPerimeters(perimeters: Array<Perimeter>): Array<Perimeter> {
  return perimeters.sort((perimeter1, perimeter2) => {
    let name1: string = perimeter1.name.toLowerCase();
    let name2: string = perimeter2.name.toLowerCase();
    let level1: number = perimeter1.level;
    let level2: number = perimeter2.level;

    let byLevel: number = level1 === level2 ? 0 : level1 < level2 ? 1 : -1; // level = 1 at the end
    let byName: number = name1 === name2 ? 0 : name1 < name2 ? -1 : 1;
    // order : level first then by alphabetical order
    return byLevel === 0 ? byName : byLevel;
  });
}

/**
 * makePerimeterType : PerimeterType constructor
 * @param jsonData
 * @returns {PerimeterType}: created perimeter type
 */
export function makePerimeterType(jsonData: any): PerimeterType {
  let defaultZone: Zone = null;
  if (jsonData.default_zone) {
    defaultZone = makeZone(jsonData.default_zone);
  }
  return new PerimeterType(
    jsonData.id,
    jsonData.name,
    jsonData.only_for_clusters,
    jsonData.has_flooring_area,
    defaultZone,
    jsonData.perimeter_type_class,
    jsonData.level,
    jsonData.default_floor,
    jsonData.automatic_asset_label_template,
    jsonData.order
  );
}

/**
 * makePerimeter : Perimeter constructor
 * @param perimeterData
 * @returns {Perimeter}: created perimeter
 */
export function makePerimeter(perimeterData: any): Perimeter {
  let building: Building = null;

  if (perimeterData.building) {
    building = new Building(
      perimeterData.building.id,
      perimeterData.building.name,
      perimeterData.building.constructionYear,
      perimeterData.building.siteName,
      perimeterData.building.monosite_perimeter,
      perimeterData.flooring_area
    );
  }

  const subPerimeters: Perimeter[] = perimeterData.sub_perimeters
    ? perimeterData.sub_perimeters.map(makePerimeter)
    : [];

  const refData: PerimeterReferenceData[] = perimeterData.ref_data
    ? perimeterData.ref_data.map(
        eltData =>
          new PerimeterReferenceData(
            eltData.id,
            eltData.perimeter,
            eltData.field,
            eltData.label,
            eltData.var_name,
            eltData.year,
            eltData.value,
            eltData.filter_value,
            eltData.aggregated,
            eltData.source,
            eltData.author,
            eltData.date
          )
      )
    : [];
  let perimeterPictures: Array<PerimeterPicture> = [];
  if (perimeterData.pictures) {
    for (let i = 0; i < perimeterData.pictures.length; i++) {
      let picture: PerimeterPicture = makePerimeterPicture(perimeterData.pictures[i]);
      perimeterPictures.push(picture);
    }
  }

  return new Perimeter(
    perimeterData.id,
    perimeterData.name,
    perimeterData.is_monosite,
    perimeterData.level,
    perimeterData.read_only,
    subPerimeters,
    perimeterData.building_id,
    perimeterData.cluster,
    perimeterData.perimeter_type,
    perimeterData.local_id || "",
    building,
    refData,
    perimeterPictures,
    perimeterData.creation_year || null,
    perimeterData.flooring_area,
    perimeterData.level_parent,
    perimeterData.level_parent_local || "",
    perimeterData.installation_date_confidence || null,
    perimeterData.code || "",
    perimeterData.coordinates || null,
    perimeterData.cover_image,
    perimeterData.site_notes || null
  );
}

export function getPerimeterElementsReferenceData(perimeter: Perimeter, varName: string): PerimeterReferenceData[] {
  return [].concat(
    perimeter.refData.filter(elementReferenceData => elementReferenceData.varName === varName),
    ...(perimeter.sub_perimeters
      ? perimeter.sub_perimeters.map(subPerimeter => getPerimeterElementsReferenceData(subPerimeter, varName))
      : [])
  );
}

export function makePerimeterPicture(jsonData: any): PerimeterPicture {
  return new PerimeterPicture(
    jsonData.id,
    jsonData.perimeter,
    jsonData.picture,
    jsonData.thumbnail,
    jsonData.user,
    jsonData.datetime,
    jsonData.local_id,
    jsonData.author,
    null,
    null,
    jsonData.is_used_for_reports
  );
}

/**
 * By default the perimeter.sub_perimeters field is empty for mono-perimeters. It's only filled for
 * sites. I use it in this function to store the children perimeters of mono-perimeters. So when we want
 * to access the children, we don't have to take the whole list and check the level_parent field
 * for each perimeter. Especially in the filters where we have abstract classes and can't access the
 * whole list.
 * @param perimetersList
 * @returns
 */
export function fillPerimetersChildren(perimetersList: Perimeter[]): Perimeter[] {
  const updatedList = perimetersList.map(perimeter => {
    perimeter.sub_perimeters = perimetersList.filter(p => p.level_parent === perimeter.id);
    return perimeter;
  });
  return updatedList;
}

export class ReferenceDataValue {
  constructor(public id: number, public label: string) {}
}

export enum ReferenceFieldDataType {
  DATA_TYPE_STRING = 0,
  DATA_TYPE_NUMBER = 1,
  DATA_TYPE_FK = 2,
  DATA_TYPE_USER = 3,
}

export enum ReferenceFieldDataFiltering {
  FILTER_NO = 0,
  FILTER_VALUE = 1,
  FILTER_RANGE = 2,
}
export class ReferenceField {
  constructor(
    public id: number,
    public varName: string,
    public label: string,
    public dataType: ReferenceFieldDataType,
    public order: number,
    public filtering: ReferenceFieldDataFiltering,
    public showSumOnMulti: boolean,
    public reportGroupingMultiPerimeters: number,
    public reportGroupingPerimetersSet: number,
    public values: Array<ReferenceDataValue>,
    public displayed: boolean,
    public onlyForPerimeterTypes: number[],
    public availableForSites: boolean,
    public source: string,
    public author: number,
    public date: string,
    public is_locked: boolean
  ) {}
}

/**
 * makeReferenceField : ReferenceField constructor
 * @param jsonData
 * @returns {Perimeter}: created perimeter
 */
export function makeReferenceField(jsonData: any): ReferenceField {
  const values: ReferenceDataValue[] = jsonData.values.map(value => new ReferenceDataValue(value.id, value.label));

  return new ReferenceField(
    jsonData.id,
    jsonData.var_name,
    jsonData.label,
    jsonData.data_type,
    jsonData.order,
    jsonData.filtering_audit,
    jsonData.show_sum_on_multi_perimeters,
    jsonData.report_grouping_multi_perimeters,
    jsonData.report_grouping_perimeters_set,
    values,
    jsonData.displayed_audit,
    jsonData.only_for_perimeter_types || [],
    jsonData.available_for_sites,
    jsonData.source,
    jsonData.author,
    jsonData.date,
    jsonData.is_locked
  );
}

export function createAssetData(asset: Asset): any {
  return {
    building: asset.building.id,
    asset_type: asset.assetType.id,
    zone: asset.zone.id,
    zone_details: asset.zoneDetails,
    installation_year: asset.installationYear,
    quantity: asset.quantity,
    duration_deviation: asset.durationDeviation,
    status: asset.status,
    ownership_type: asset.ownershipType ? asset.ownershipType.id : null,
    comments: asset.comments,
    maintenance_code: asset.maintenanceCode,
    local_id: asset.offlineId,
  };
}

export function makeNotationMissingComment(jsonData: any): NotationMissingComment {
  return new NotationMissingComment(jsonData.id, jsonData.notation_missing_reason, jsonData.default_comment);
}

export class AssetLifecycle {
  constructor(
    public endOfLifeYearWithInvest: number,
    public expectedLifetimeWithInvest: number,
    public endOfLifeYearNoInvest: number,
    public expectedLifetimeNoInvest: number
  ) {}
}

export enum StepsIds {
  CAMERA = 1,
  PERIMETER = 2,
  ASSETTYPE = 3,
  DETAIL = 4,
  LIFECYCLE = 5,
  OTHERNOTATIONS = 6,
  EXPERT = 7,
  PARENT = 8,
}

export class PriceSheet {
  constructor(
    public id: number,
    public asset_type: number,
    public author: AppUser,
    public comments: string,
    public country: string,
    public is_default: boolean,
    public label: string,
    public model: string,
    public modified_on: Date,
    public quantity: number,
    public quantity_unit: QuantityUnit,
    public region: string,
    public unit_price: number,
    public year: number,
    public budgetScope: IBudgetScope,
    public is_benchmark: boolean,
    public is_customer: boolean
  ) {}
}

export function makePriceSheet(jsonData: any) {
  let unitPriceInFloat = jsonData.unit_price,
    quantityInFloat = jsonData.quantity;
  try {
    unitPriceInFloat = parseFloat(jsonData.unit_price);
    quantityInFloat = parseFloat(jsonData.quantity);
  } catch (error) {
    console.warn("Pricesheet data seems to be incorrect (unit_price|quantity) should be parseable to float!", jsonData);
  }
  return new PriceSheet(
    jsonData.id,
    jsonData.asset_type,
    jsonData.author,
    jsonData.comments || "",
    jsonData.country,
    jsonData.is_default,
    jsonData.label,
    jsonData.model,
    jsonData.modified_on,
    quantityInFloat,
    jsonData.quantity_unit,
    jsonData.region,
    unitPriceInFloat,
    jsonData.year,
    jsonData.budget_scope,
    jsonData.is_benchmark,
    jsonData.is_customer
  );
}

// The options that will be available if the user swipes an asset-item
export type ItemOptions = {
  synchronize?: boolean;
  cloneAsset?: boolean;
  deleteAsset?: boolean;
};
