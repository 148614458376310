import { AfterViewInit, Component, OnInit } from "@angular/core";
import { AuthService } from "@services/auth.service";
import { forkJoin, Subscription } from "rxjs";
import { getPicturesLoadingPermission, setPicturesLoadingPermission, User } from "@structs";
import { Settings, SettingsService } from "@services/settings.service";
import { Change, SynchronizationState, SynchronizationStatus } from "@structs/synchronization";
import { SynchronizationService } from "@services";
import { TranslateService } from "@ngx-translate/core";
import { ErrorsService } from "@services/errors.service";
import { EventService, SaveToLocalService } from "../../services";
import { SuccessToastService } from "@services/success-toast.service";
import { Router } from "@angular/router";
import { AlertController, MenuController, Platform, ToastController } from "@ionic/angular";
import { BrowserService } from "src/app/services/browser.service";
import { InformationService } from "src/app/services/information.service";
import { EmailComposer } from "@awesome-cordova-plugins/email-composer/ngx";
import { Environment } from "src/app/app.environment";
import { catchError, concatAll, map, switchMap, tap } from "rxjs/operators";
import * as JSZip from "jszip";
import { StorageService } from "@services/storage.service";
import { BackendService } from "@services/backend.service";
import { PicturesService } from "@services/pictures.service";
import * as IonicFile from "@ionic-native/file";
import { File } from "@awesome-cordova-plugins/file/ngx";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"],
})
export class MainMenuComponent implements OnInit, AfterViewInit {
  user: User;

  userDisplayName: string;

  settings: Settings;

  subscriptions: Subscription[] = [];

  loadPictures = false;

  fullSize = 0;

  confirmTitle: string = "";
  confirmMessage: string = "";
  okButton: string = "";
  cancelButton: string = "";
  progressMessage: string = "";
  lastHelloDelay: number;
  changesCount: number;
  forceSynchroEnabled: any;
  darkTheme: boolean = false;
  platformName: string = Environment.getName();

  constructor(
    private authService: AuthService,
    private settingsService: SettingsService,
    private syncApi: SynchronizationService,
    private translate: TranslateService,
    private events: EventService,
    private errors: ErrorsService,
    private successToastService: SuccessToastService,
    private router: Router,
    private menuCtrl: MenuController,
    private browserService: BrowserService,
    private informationService: InformationService,
    private emailComposer: EmailComposer,
    private saveToLocal: SaveToLocalService,
    private platform: Platform,
    private storage: StorageService,
    protected toastCtrl: ToastController,
    private backend: BackendService,
    private alertController: AlertController,
    private picturesService: PicturesService,
    private file: File
  ) {}

  ngOnInit(): void {
    this.authService.onCurrentUserChanged().subscribe(user => {
      this.user = user;
      if (user) {
        this.userDisplayName = this.user.getDisplayName();
      }
    });
    this.settingsService.getAppSettings().subscribe(settings => {
      this.settings = settings;
      this.fullSize = settings.offlineStorageSize;
      this.lastHelloDelay = settings.lastHelloDelay;
    });
    this.getChanges();
    this.loadPictures = getPicturesLoadingPermission();

    this.translate.get("Confirmation").subscribe(title => {
      this.confirmTitle = title;
    });
    this.translate.get("Do you want to delete all local storage?").subscribe(text => {
      this.confirmMessage = text;
    });
    this.translate.get("Ok").subscribe(okButton => {
      this.okButton = okButton;
    });
    this.translate.get("Cancel").subscribe(cancelButton => {
      this.cancelButton = cancelButton;
    });
    this.translate.get("In progress...").subscribe(text => {
      this.progressMessage = text;
    });
    this.events.subscribe("updateChangesCount", () => {
      this.getChanges();
    });
  }

  ngAfterViewInit(): void {
    this.subscriptions.forEach(subscription => subscription && subscription.unsubscribe());
  }

  public picturesToggle() {
    this.events.publish("picturesToggle", this.loadPictures);
    setPicturesLoadingPermission(this.loadPictures);
  }

  public getChanges() {
    this.syncApi.getChanges().subscribe((changes: Change[]) => {
      if (changes) {
        this.changesCount = changes.length;
        this.forceSynchroEnabled = true;
      } else {
        this.changesCount = 0;
      }
    });
    // FIXME: the subscription doesn't work if we use this subscription array.
    // The main menu fails to get the number of pending changes.

    // this.subscriptions.push(
    this.syncApi.watchSynchronizationState().subscribe((synchronizationState: SynchronizationState) => {
      if (synchronizationState.status === SynchronizationStatus.STARTED) {
        this.forceSynchroEnabled = false;
      } else {
        this.syncApi.getChanges().subscribe(
          (changes: Change[]) => {
            this.changesCount = changes.length;
            if (
              synchronizationState.status === SynchronizationStatus.DONE ||
              synchronizationState.status === SynchronizationStatus.POSTPONED ||
              synchronizationState.status === SynchronizationStatus.PUSH
            ) {
              this.forceSynchroEnabled = this.changesCount > 0;
            }
          },
          err => {
            this.errors.signalError(err);
          }
        );
      }
      this.lastHelloDelay = this.settingsService.getLastHelloDelay();
    });
    // );
  }

  forceSynchro(): void {
    if (this.forceSynchroEnabled) {
      this.syncApi.makeSynchronizationVerbose();
      this.syncApi.pushOfflineChanges(false, true).subscribe(
        () => {},
        err => {
          this.errors.signalError(err);
        },
        () => {}
      );
    }
  }

  supportEmail(): void {
    const inBrowser = this.browserService.inBrowser();
    const kpiSupport = Environment.getSupportEmail();

    forkJoin([
      this.translate.get("CAPEX Audit - Support request"),
      this.informationService.recoverApplicationDetails(),
    ]).subscribe(([subject, body]) => {
      if (inBrowser) {
        this.browserService.openUrl("mailto:" + kpiSupport + "?subject=" + subject + "&body=" + body);
      } else {
        this.emailComposer
          .open({
            to: kpiSupport,
            subject,
            body,
            isHtml: true,
          })
          .then();
      }
    });
  }

  // Send the diagnostic information to the backend
  sendDiagnostics(): void {
    console.debug("Sending diagnostic information to backend");
    this._sendDiagnostic();
  }

  // Get the whole local database (websql/indexeddb) and zip it
  private async getDiagnosticInformation(): Promise<Blob> {
    const zip = new JSZip();
    const debugKeys = Object.keys(localStorage);
    for (const debugKey of debugKeys) {
      zip.file(`./debug/${debugKey}`, localStorage.getItem(debugKey));
    }
    const keys = await this.storage.getAllDatabaseKeys();
    let value;
    for (const key of keys) {
      value = await this.storage.get(key);
      zip.file(`./diagnotics/${key}`, value);
    }

    const pictureKeys = await this.picturesService.getAllPictureKeysPendingToUpload().toPromise();
    for (const key of pictureKeys) {
      const file = await this.picturesService.getPicturePendingToUpload(key).toPromise();
      console.log(`saving ${key} as ${file}`);
      zip.file(`./pictures/${key}`, file);
    }

    if (!this.browserService.inBrowser()) {
      let targetDirectory;
      if (IonicFile.File.externalDataDirectory) {
        // Running on Android
        targetDirectory = IonicFile.File.externalDataDirectory;
      } else if (IonicFile.File.documentsDirectory) {
        // Running on iOS
        targetDirectory = IonicFile.File.documentsDirectory;
      } else {
        throw new Error("Unsupported platform");
      }
      const files = await this.file.listDir(targetDirectory, "");
      for (const file of files) {
        if (file.name.endsWith(".jpg")) {
          const fileContent = await IonicFile.File.readAsArrayBuffer(targetDirectory, file.name);
          zip.file(`./pictures/${file.name}`, fileContent);
        }
      }
    }

    return await zip.generateAsync({ type: "blob", compression: "DEFLATE" });
  }

  // Send the zip file to the backend
  private async _sendDiagnostic(): Promise<void> {
    const content = await this.getDiagnosticInformation();
    const formData: FormData = new FormData();
    this.backend.getHeaders().then(headers => {
      const newHeaders = headers.set("Content-Type", "multipart/form-data");
      formData.append("diagnosticFile", content, "KPI-diagnostic.dbg");
      const msg = this.translate.instant("Diagnostic sent");
      this.backend
        ._doCallApi("post", "/monitoring/diagnostics/", content, newHeaders, {})
        .pipe(
          tap(() => this.showToast(msg, 2000, "successToast-diagnostic").then()),
          catchError(() => this.errorDialogWhenBackendFails(content).then())
        )
        .subscribe();
    });
  }

  // Fallback if backend fails to receive the file, we save it locally
  private async errorDialogWhenBackendFails(content: Blob) {
    const alert = await this.alertController.create({
      header: this.translate.instant("Error"),
      message: this.translate.instant("Cannot send the file to the server, please save it locally"),
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: () => this.saveFile("diagnostic.dbg", content),
        },
      ],
    });
    await alert.present();
  }

  // save file to local and show a toast
  private async saveFile(filename: string, content: string | Blob) {
    try {
      const file = await this.saveToLocal.saveToDownload(filename, content);
      this.translate
        .get("your device")
        .pipe(
          switchMap(msg =>
            this.translate.get("The debug files are saved to {{filepath}}. Please send them to support", {
              filepath: file?.fullPath || msg,
            })
          )
        )
        .pipe(tap(msg => this.showToast(msg, 5000)))
        .subscribe();
      console.log("Saved to local", file);
    } catch (error) {
      console.warn("Error saving file", error);
      await this.showToast(this.translate.instant("Could not save debug file"), 2000);
    }
  }

  // Generic function to show a toast
  private async showToast(msg: string, duration = 1000, cssClass: string = null) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: duration,
      position: "bottom",
      cssClass: cssClass,
    });

    await toast.present();
  }

  async logout(): Promise<void> {
    this.authService.logout();
    await this.successToastService.showSuccessToast(0, "Logged out");
    await this.router.navigate(["/"]).then(() => window.location.reload());
  }

  showSyncChanges() {
    this.menuCtrl.close();
    this.router.navigate(["sync-changes"]);
  }

  legalInformation() {
    this.browserService.openUrl("https://kpi-intelligence.com/terms-of-use/");
  }

  public showMoreSettings() {
    this.menuCtrl.close();
    this.router.navigate(["app-settings"]);
  }

  private async showSynchroAlert() {
    const synchroAlert = this.alertController.create({
      header: this.translate.instant("Offline mode"),
      message: this.translate.instant(
        "Data is stored locally - do not forget to reactivate online mode to send data to the remote server"
      ),
      buttons: [
        {
          text: this.translate.instant("I understand"),
        },
      ],
    });
    (await synchroAlert).present();
  }
}
