import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import {
  ASSET_STEP_ACTIVE,
  Asset,
  Investment,
  Perimeter,
  PerimeterType,
  Picture,
  cleanFilterString,
  formatSearchString,
} from "@structs";
import { PerimeterElement } from "../monoperimeterlist.component";
import { NavigationExtras, Router } from "@angular/router";
import { NavController, IonItemSliding } from "@ionic/angular";
import { OfflineService } from "@services";
import { ErrorsService } from "@services/errors.service";
import { PerimetersService } from "@services/perimeters.service";
import { SynthesisService } from "@services/synthesis.service";
import { Observable, combineLatest, from } from "rxjs";
import { InvestmentsService } from "@services/investments.service";
import { concatMap, map } from "rxjs/operators";
import { PerimeterTypeSection } from "@services/roadmap-chapters.service";
import { TranslateService } from "@ngx-translate/core";
import * as R from "ramda";
import { ConfigService } from "@services/config.service";
import { RiskService } from "@services/risks.service";
import { Risk } from "@structs/risk";

@Component({
  selector: "app-mono-perimeter-list-segment",
  templateUrl: "./mono-perimeter-list-segment.component.html",
  styleUrls: ["./mono-perimeter-list-segment.component.scss"],
})
export class MonoPerimeterListSegmentComponent implements OnChanges {
  @Input() public mainPerimeter: Perimeter = null;
  @Input() public hideChildren: boolean = false;
  @Input() public searchText: string = "";
  @Input() public onlyBuildings: boolean = false;
  // If we want to restrain the list of possible perimeters choices
  @Input() public limitedList: number[] = null;
  @Input() public childrenPerimetersMode: boolean = false;
  @Input() public selectedMonoPerimeterId: number | string = null;
  @Input() public showSwipeButtons: boolean = false;
  @Input() public newPerimeter: Perimeter = null;
  public searchedPerimeters: PerimeterElement[] = [];

  private perimeterTypes: PerimeterType[] = [];
  private perimeterTypesNamesMap: Map<number, string> = new Map<number, string>();
  public pictureEmpty: Picture = new Picture(-1, null, null, null, null, null, null);

  // These 2 arrays are initialised at null and not at [], so we know
  // if they are empty by default or because the user unfolded everything.
  private collapsedParentsIds: number[] = null;
  private collapsedPerimeterTypeSections: number[] = null;

  private siteInvestments: Investment[] = [];
  private siteAssets: Asset[] = [];
  public risksEnabled$ = this.configService.customerConfig$.pipe(
    map(customerConfig => customerConfig.risk_module_enabled)
  );
  private siteRisksList: Risk[] = [];
  @Output() public monoPerimeterClicked = new EventEmitter<Perimeter>();
  @Output() public pictureClickedEv = new EventEmitter<Perimeter>();
  @Output() public deleteMonoPerimeterEv = new EventEmitter<Perimeter>();

  constructor(
    private errorsService: ErrorsService,
    private navCtrl: NavController,
    private perimetersService: PerimetersService,
    private offline: OfflineService,
    private router: Router,
    private synthesisService: SynthesisService,
    private investmentsService: InvestmentsService,
    private translate: TranslateService,
    private configService: ConfigService,
    private riskService: RiskService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mainPerimeter && changes.mainPerimeter.currentValue) {
      this.init();
    }
  }

  selectPerimeter(monoPerimeter: Perimeter): void {
    this.monoPerimeterClicked.emit(monoPerimeter);
  }

  public getPerimeterTypeName(monoPerimeter): string {
    let perimeterTypeName = this.perimeterTypesNamesMap.get(monoPerimeter.perimeterType) || "";
    return perimeterTypeName;
  }

  public addPerimeter(): void {
    this.router.navigate(["mono-perimeter-add"]);
  }

  public searchPerimeters(): void {
    this.initializeSearchedPerimeters();
    const searchText = cleanFilterString(this.searchText);
    const keys = Array.from(this.perimeterTypesNamesMap.keys());
    let matchingPerimeterTypeIds = keys.filter((key: number) => {
      let name = this.perimeterTypesNamesMap.get(key);
      return cleanFilterString(name).includes(searchText);
    });
    this.searchedPerimeters = this.mainPerimeter.sub_perimeters
      .filter(perimeter => {
        return (
          (cleanFilterString(perimeter.name).includes(searchText) ||
            matchingPerimeterTypeIds.indexOf(perimeter.perimeterType) >= 0) &&
          (this.limitedList === null || this.limitedList.includes(perimeter.id))
        );
      })
      .map(perimeter => ({
        perimeter,
        perimeterTypeName: this.perimeterTypesNamesMap.get(perimeter.perimeterType),
        hasLevelChildren: this.mainPerimeter.sub_perimeters.some(p => p.level_parent === perimeter.id),
      }));

    this.sortSearchedPerimeters();
  }

  private init(): void {
    this.offline.getPerimeterOfflineMap().subscribe(perimetersOfflineMap => {
      // deal with offline syncing having now happened
      for (let perim of this.mainPerimeter.sub_perimeters.filter(per => per.id === -1)) {
        let perimId = perimetersOfflineMap.perimeters[perim.localId] || 0;
        if (perimId) {
          perim.id = perimId;
        }
      }

      this.searchedPerimeters = [];
      combineLatest([
        this.perimetersService.getPerimeterTypes(),
        this.getPlannedInvestments(),
        this.synthesisService.getAssets(),
      ]).subscribe(
        ([perimeterTypes, investments, siteAssets]) => {
          this.siteInvestments = investments;
          this.siteAssets = siteAssets.filter(asset => asset.step === ASSET_STEP_ACTIVE);
          this.perimeterTypes = perimeterTypes;
          this.perimeterTypesNamesMap.clear();
          for (let perimeterType of this.perimeterTypes) {
            this.perimeterTypesNamesMap.set(perimeterType.id, perimeterType.name);
          }
          this.initializeSearchedPerimeters();
        },
        err => {
          this.errorsService.signalError(err);
        }
      );
    });

    this.riskService.siteRisks$.subscribe(siteRisks => {
      this.siteRisksList = siteRisks;
    });
  }

  private initializeSearchedPerimeters() {
    for (let perimeter of this.mainPerimeter.sub_perimeters) {
      if (
        (!this.onlyBuildings || this.isBuilding(perimeter)) &&
        (this.limitedList === null || this.limitedList.includes(perimeter.id))
      ) {
        let budget: number = 0;
        this.siteInvestments.map(investment => {
          if (investment.buildingId === perimeter.building_id) {
            budget += investment.getPrice();
          }
        });
        const perimeterAssets = this.siteAssets.filter(asset =>
          this.perimetersService.assetBelongsToThisPerimeter(asset, perimeter)
        );
        let assetCount = perimeterAssets.length;
        let criticalAssetsCount = perimeterAssets.filter(asset => asset.level === "critical").length;
        let assetsOnChildrenPerimeters = this.siteAssets.filter(asset =>
          this.perimetersService.assetBelongsToChildrenPerimeters(asset, perimeter, this.mainPerimeter)
        );
        let assetCountOnChildren = assetsOnChildrenPerimeters.length;
        let criticalAssetsCountOnChildren = assetsOnChildrenPerimeters.filter(
          asset => asset.level === "critical"
        ).length;

        this.searchedPerimeters.push({
          perimeter,
          perimeterTypeName: this.perimeterTypesNamesMap.get(perimeter.perimeterType),
          hasLevelChildren: this.mainPerimeter.sub_perimeters.some(p => p.level_parent === perimeter.id),
          budget: budget,
          assetCount: assetCount,
          assetCountOnChildren: assetCountOnChildren,
          criticalAssetsCount: criticalAssetsCount,
          criticalAssetsCountOnChildren: criticalAssetsCountOnChildren,
        });
      }
    }
    this.sortSearchedPerimeters();
    // Fold the lists if the page is initialising (but not if the user has already opened stuff)
    if (!this.collapsedParentsIds) {
      this.collapsedParentsIds = this.searchedPerimeters.map(perimeterElt => perimeterElt.perimeter.id);

      const allSections: PerimeterTypeSection[] = R.flatten(
        this.searchedPerimeters.map(perimeterElt => perimeterElt.perimeterTypeSections).filter(section => !!section)
      );
      // The perimeter type sections are folded by default unless they have only 1 member
      this.collapsedPerimeterTypeSections = allSections
        .filter(section => section.perimeters.length > 1)
        .map(section => section.id);
    }

    if (this.selectedMonoPerimeterId) {
      const selectedMonoPerimeter = this.mainPerimeter.sub_perimeters.find(perimeter => this.isSelected(perimeter));
      this.unfoldParent(selectedMonoPerimeter);
    }
    if (this.newPerimeter) {
      this.unfoldParent(this.newPerimeter);
      this.newPerimeter = null;
    }
  }

  private sortSearchedPerimeters(): void {
    if (this.childrenPerimetersMode) {
      return;
    }
    let children = new Map();
    for (let perimeterElt of this.searchedPerimeters) {
      if (perimeterElt.perimeter.level_parent !== null) {
        const parentKey =
          perimeterElt.perimeter.level_parent !== -1
            ? perimeterElt.perimeter.level_parent
            : perimeterElt.perimeter.level_parent_local_id;
        if (!children.has(parentKey)) {
          children.set(parentKey, []);
        }
        children.get(parentKey).push(perimeterElt);
      }
    }

    let superSortedPerimeters: PerimeterElement[] = [];
    for (let perimeterElt of this.searchedPerimeters) {
      if (perimeterElt.perimeter.level_parent === null) {
        superSortedPerimeters.push(perimeterElt);
      }

      // The child may know his parent by is id or by is localId : look for children with both
      const parentKeys = [];
      if (perimeterElt.perimeter.id !== -1) {
        parentKeys.push(perimeterElt.perimeter.id);
      }
      if (perimeterElt.perimeter.localId) {
        parentKeys.push(perimeterElt.perimeter.localId);
      }

      for (let parentKey of parentKeys) {
        if (children.has(parentKey)) {
          // Sorting this parent's children by their perimeter type
          const sortedChildren = this.sortByPerimeterType(children.get(parentKey), this.perimeterTypes);
          // Adding the new children group to their parent (the last parent in the array)
          superSortedPerimeters[superSortedPerimeters.length - 1].perimeterTypeSections = sortedChildren;
        }
      }
    }
    this.searchedPerimeters = superSortedPerimeters;
  }

  public showEnergyConsumptionPage() {
    this.router.navigate(["energy-consumption", this.mainPerimeter.id]);
  }

  public async pictureClicked(event, perimeter) {
    event.stopPropagation();
    event.preventDefault();
    this.pictureClickedEv.emit(perimeter);
  }

  // go to multi perimeter page
  public goToSitePage() {
    this.navCtrl.navigateBack(["perimeter"]);
  }

  public toggleMultiPerimeterChildren(perimeter: Perimeter): void {
    if (perimeter && !perimeter.level_parent) {
      if (this.isCollapsed(perimeter.id)) {
        this.collapsedParentsIds = this.collapsedParentsIds.filter(id => id !== perimeter.id);
      } else {
        this.collapsedParentsIds.push(perimeter.id);
      }
    }
  }

  public isCollapsed(perimeterId: number): boolean {
    return !this.collapsedParentsIds || this.collapsedParentsIds.includes(perimeterId);
  }

  public isBuilding(perimeter: Perimeter): boolean {
    const perimeterType = this.perimeterTypes.find(perimeterType => perimeterType.id === perimeter.perimeterType);
    return perimeterType && perimeterType.perimeterTypeClass === 2;
  }

  public deletePerimeter(item: IonItemSliding, perimeterElt): void {
    item.close();
    const monoPerimeter = perimeterElt.perimeter;
    this.deleteMonoPerimeterEv.emit(monoPerimeter);
  }

  public isSelected(monoPerimeter: Perimeter): boolean {
    let selected: boolean = false;
    if (typeof this.selectedMonoPerimeterId === "number") {
      selected = monoPerimeter.id === this.selectedMonoPerimeterId;
    } else {
      selected = monoPerimeter.localId === this.selectedMonoPerimeterId;
    }
    return selected;
  }

  // If a mono-perimeter is selected or new, we need to see it. So if it has a parent,
  // we need to unfold it.
  private unfoldParent(monoPerimeter: Perimeter) {
    if (monoPerimeter?.level_parent) {
      if (this.isCollapsed(monoPerimeter.level_parent)) {
        // Unfold the parent
        this.collapsedParentsIds = this.collapsedParentsIds.filter(id => id !== monoPerimeter.level_parent);
      }
      if (this.isSectionCollapsed(monoPerimeter.perimeterType)) {
        // Unfold the perimeter type section
        this.collapsedPerimeterTypeSections = this.collapsedPerimeterTypeSections.filter(
          id => id !== monoPerimeter.perimeterType
        );
      }
    }
  }

  public addMonoPerimeter(item: IonItemSliding, perim?: Perimeter) {
    item.close();
    const navExtras: NavigationExtras = {
      state: {
        fixedLevelParent: perim.id,
        parentLocalId: perim.id === -1 ? perim.localId : null,
        parentCreationYear: perim.creationYear,
        fromRoadmap: false,
      },
    };
    this.router.navigate(["mono-perimeter-add"], navExtras);
  }

  public showMonoPerimeter(item: IonItemSliding, monoPerimeter: Perimeter) {
    item.close();
    this.router.navigate(["perimeter/mono-perimeters/" + monoPerimeter.id || monoPerimeter.localId]);
  }

  private getPlannedInvestments(): Observable<Investment[]> {
    return this.investmentsService
      .getPerimeterInvestments(this.mainPerimeter, true)
      .pipe(map(investments => investments.filter(invest => !invest.status.hypothesis && !invest.status.isLocked)));
  }

  private getAssetCount(perimeterElt: PerimeterElement): number {
    if (perimeterElt.hasLevelChildren && !this.isCollapsed(perimeterElt.perimeter.id)) {
      return perimeterElt.assetCount;
    } else {
      return perimeterElt.assetCount + perimeterElt.assetCountOnChildren;
    }
  }

  private sortByPerimeterType(
    perimeterElements: PerimeterElement[],
    perimeterTypes: PerimeterType[]
  ): PerimeterTypeSection[] {
    let perimeterTypeSections: PerimeterTypeSection[] = [];

    perimeterTypes.forEach(type => {
      let group = perimeterElements.filter(perimeterElt => perimeterElt.perimeter.perimeterType === type.id);
      if (group.length > 0) {
        perimeterTypeSections.push({
          id: type.id,
          name: type.name,
          perimeters: group,
        });
      }
    });
    return perimeterTypeSections.sort((a, b) => {
      if (formatSearchString(a.name) < formatSearchString(b.name)) {
        return -1;
      }
      if (formatSearchString(a.name) > formatSearchString(b.name)) {
        return 1;
      }
      return 0;
    });
  }

  public toggleSection(perimeterTypeId: number) {
    if (this.isSectionCollapsed(perimeterTypeId)) {
      this.collapsedPerimeterTypeSections = this.collapsedPerimeterTypeSections.filter(id => id !== perimeterTypeId);
    } else {
      this.collapsedPerimeterTypeSections.push(perimeterTypeId);
    }
  }

  public isSectionCollapsed(perimeterTypeId: number): boolean {
    return !this.collapsedPerimeterTypeSections || this.collapsedPerimeterTypeSections.includes(perimeterTypeId);
  }

  public getParentColor(perimeterElt: PerimeterElement) {
    return this.isSelected(perimeterElt.perimeter)
      ? "selected-blue"
      : this.isCollapsed(perimeterElt.perimeter.id)
      ? null
      : "selected-blue-2";
  }

  // We show the section only if there are multiple sections for the parent perimeter
  // and multiple children inside this section. Otherwise, the children are unfolded
  public showSection(perimeterElt: PerimeterElement, perimeterTypeSection: PerimeterTypeSection) {
    return perimeterElt.perimeterTypeSections?.length > 1 && perimeterTypeSection.perimeters.length > 1;
  }

  public getLinkedAssetsSentence(perimeterElt: PerimeterElement): string {
    let assetSentence = "";
    const assetCount = this.getAssetCount(perimeterElt);
    if (!assetCount) {
      assetSentence = this.translate.instant("No linked equipement");
    } else {
      assetSentence =
        assetCount > 1
          ? assetCount + " " + this.translate.instant("assets")
          : assetCount + " " + this.translate.instant("asset");
    }
    return assetSentence;
  }

  public getChildrenCount(perimeterElt: PerimeterElement): number {
    let childrenCount = 0;
    perimeterElt.perimeterTypeSections.forEach(section => {
      if (section.perimeters) {
        childrenCount += section.perimeters.length;
      }
    });
    return childrenCount;
  }

  public getCriticalAssetsSentence(perimeterElt: PerimeterElement) {
    let sentence = "";
    const criticalAssetsCount = this.getCriticalAssetsCount(perimeterElt);
    if (criticalAssetsCount) {
      sentence = this.translate.instant("including {{ count }} critical", { count: criticalAssetsCount });
    }
    return sentence;
  }

  public getCriticalAssetsCount(perimeterElt: PerimeterElement): number {
    if (perimeterElt.hasLevelChildren && this.isCollapsed) {
      return perimeterElt.criticalAssetsCount + perimeterElt.criticalAssetsCountOnChildren;
    } else {
      return perimeterElt.criticalAssetsCount;
    }
  }

  private getMonoPerimeterRiskCount(monoPerimeterId: number) {
    const riskList = this.siteRisksList.filter(risk => risk.perimeter === monoPerimeterId);
    return riskList?.length;
  }

  getRiskSentence(monoPerimeterId: number) {
    const riskCount = this.getMonoPerimeterRiskCount(monoPerimeterId);
    if (riskCount != null) {
      const translateParams = {
        riskCount: riskCount,
      };
      if (riskCount > 1) {
        return this.translate.instant("{{ riskCount }} risks identified", translateParams);
      } else {
        return this.translate.instant("{{ riskCount }} risk identified", translateParams);
      }
    }
    return "";
  }

  getRiskColor(monoPerimeterId: number) {
    if (this.getMonoPerimeterRiskCount(monoPerimeterId)) {
      return "color-danger";
    }
    return "";
  }
}
