// The file contents for the current environment for staging purpose

export const environment = {
  name: "test",
  backendHost: "https://kpi-test-app.azurewebsites.net",
  capexIntelligenceHost: "https://test.capex-intelligence.com",
  logo: "logo_kpi_no_bg.png",
  production: true,
  test: false,
  hasGoogleLogin: false,
  hasPasswordLogin: true,
  sentryDSN: "https://a2627fcf3f344a73b1c12e1b7cb5eb63@sentry.io/1447058",
  showDocDefaultsInInvestmentWizard: false,
  supportEmail: null,
  supportPhone: "+33481695073",
  roadmapConfiguration: 1,
  hasMicrosoftLogin: true,
};
