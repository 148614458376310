import { environment } from "../environments/environment.test";

export class Environment {
  constructor() {}

  public static getName(): string {
    return environment.name;
  }

  public static getBackendHost(): string {
    return environment.backendHost;
  }

  public static getCAPEXIntelligenceHost(): string {
    return environment.capexIntelligenceHost.replace("127.0.0.1", "localhost");
  }

  public static getLogo(): string {
    return "assets/logos/" + environment.logo;
  }

  public static isProduction(): boolean {
    return environment.production;
  }

  public static isTest(): boolean {
    return environment.test;
  }

  public static hasPasswordLogin(): boolean {
    return environment.hasPasswordLogin;
  }

  public static hasGoogleLogin(): boolean {
    return environment.hasGoogleLogin;
  }

  public static hasMicrosoftLogin(): boolean {
    return environment["hasMicrosoftLogin"];
  }

  public static getSentryDSN(): string {
    return environment.sentryDSN;
  }

  public static getshowDocDefaultsInInvestmentWizard(): boolean {
    return environment.showDocDefaultsInInvestmentWizard;
  }

  public static getSupportEmail(): string {
    return environment.supportEmail || "support@kpi-intelligence.com";
  }

  public static getSupportPhone(): string {
    return environment.supportPhone || "+33481695073";
  }

  public static getRoadmapConfiguration(): number {
    // @ts-ignore
    return environment.roadmapConfiguration || 1;
  }
}
