import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ActionSheetController, AlertController, IonContent, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { SearchService, SynchronizationService } from "@services";
import { InvestmentsService } from "@services/investments.service";
import { RiskService } from "@services/risks.service";
import { ScopeService } from "@services/scope.service";
import { RISK_TASK, SuccessToastService } from "@services/success-toast.service";
import { TasksService } from "@services/tasks.service";
import { Investment, Perimeter, Task } from "@structs";
import { Risk, RiskCategory, RiskCriticality } from "@structs/risk";
import { combineLatest, from, Observable, of, Subscription } from "rxjs";
import { concatMap, filter, map, switchMap, tap } from "rxjs/operators";
import { TaskDetailComponent } from "src/app/pages/tasks/task-detail/task-detail.component";
import { TaskState } from "@structs/tasks";
import { ErrorsService } from "@services/errors.service";
import { CurrencyPipe } from "src/app/pipes/currency/currency.pipe";

@Component({
  selector: "app-risks-list",
  templateUrl: "./risks-list.component.html",
  styleUrls: ["./risks-list.component.scss"],
})
export class RisksListComponent implements OnInit, OnDestroy {
  @Input() perimeter: Perimeter; // Mono-perimeter
  @Input() riskSubCategoryId: number; // When coming from the investment sheet, we have to scroll to this.
  public site: Perimeter;
  public riskCategories$: Observable<RiskCategory[]>;
  public riskCategories: RiskCategory[] = [];
  public perimeterRisks: Risk[] = [];
  public collapsedSections: number[] = null;
  private subscriptions: Subscription[] = [];
  private investments: Investment[] = [];
  @ViewChild("browserFileUpload") browserFileUpload: ElementRef;
  @ViewChild("content") content: IonContent;

  constructor(
    private riskService: RiskService,
    private scope: ScopeService,
    private tasksService: TasksService,
    private modalCtrl: ModalController,
    private successToastService: SuccessToastService,
    private translate: TranslateService,
    private investmentsService: InvestmentsService,
    private searchService: SearchService,
    private errorService: ErrorsService,
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    this.riskCategories$ = this.riskService.getAllRisks();
    if (this.perimeter) {
      this.loadRisks();
    }
    this.subscriptions.push(
      this.scope.getCurrentMultiPerimeter().subscribe(multiPerimeter => {
        this.site = multiPerimeter;
      }),
      this.riskService.riskListNeedsRefresh$.subscribe(() => {
        this.loadRisks();
      })
    );
  }

  loadRisks() {
    this.riskService.getPerimeterRisks(this.perimeter.id).subscribe(perimeterRisks => {
      this.perimeterRisks = perimeterRisks.map(risk => {
        // The risk pictures coming from the backend don't have right type so we have to
        // convert them for our Picture component.
        risk.images = risk.images.map(image => this.riskService.formatForPictureComponent(image));
        return risk;
      });
      if (this.collapsedSections === null) {
        this.initCategories();
      }
      this.loadInvestments();
    });
  }

  visibilityToggle(id: number) {
    if (this.isVisible(id)) {
      this.collapsedSections = this.collapsedSections.filter(categoryId => categoryId !== id);
    } else {
      this.collapsedSections = [].concat(this.collapsedSections, id);
    }
  }

  isVisible(id: number): boolean {
    return this.collapsedSections?.includes(id);
  }

  getRiskColor(risk: Risk): string {
    let color: string;
    switch (risk.criticality) {
      case 1:
        color = "third-gain";
        break;
      case 2:
        color = "roadmap";
        break;
      case 3:
        color = "danger";
        break;
      default:
        color = "third-gain";
        break;
    }
    return color;
  }

  getPerimeterRisk(riskSubCategoryId: number): Risk {
    const risk = this.perimeterRisks.find(r => r.sub_category?.id === riskSubCategoryId);
    return risk;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getRisksForCategory(riskCategory: RiskCategory): number {
    return riskCategory.sub_categories.filter(subCat => this.getPerimeterRisk(subCat.id)).length;
  }

  /**
   * Set which categories are folded/unfolded
   */
  private initCategories() {
    this.subscriptions.push(
      this.riskCategories$
        .pipe(tap(riskCategories => (this.riskCategories = riskCategories)))
        .subscribe(riskCategories => {
          riskCategories.forEach(category => {
            if (!!this.getRisksForCategory(category)) {
              if (this.collapsedSections) {
                this.collapsedSections.push(category.id);
              } else {
                this.collapsedSections = [category.id];
              }
            }
          });
          if (this.riskSubCategoryId) {
            // Scroll to the specified risk.
            setTimeout(() => {
              let y = document.getElementById("sub-category" + this.riskSubCategoryId).offsetTop;
              this.content.scrollToPoint(0, y, 500);
              // The settimeout is a hack because the pictures add some offset so we
              // have to get the offset after the pictures appear. We don't have to wait until they
              // are fully loaded though.
            }, 200);
          }
        })
    );
  }

  /**
   * Opens a new task form.
   */
  public addTask(risk: Risk) {
    this.tasksService.initNewTask(this.perimeter.id).subscribe(async newTask => {
      const taskModal = await this.modalCtrl.create({
        component: TaskDetailComponent,
        componentProps: {
          task: newTask,
          risk: risk,
          modalMode: true,
        },
      });
      await taskModal.present();
      const { data } = await taskModal.onWillDismiss();
      if (data?.task) {
        this.riskService.createTask(risk.id, data.task).subscribe(taskCreated => {
          const text = this.translate.instant("1 risk task created");
          this.successToastService.showSuccessToast(0, text, 3000);
          this.loadRisks();
        });
      }
    });
  }

  public deleteRisk(risk: Risk) {
    if (!risk) {
      return;
    }
    // Before deleting a risk, we need to check the investment status and the task status
    let loadInvestment$: Observable<Investment> = of(null);
    let loadTask$: Observable<Task> = of(null);
    if (risk.investment_ids.length) {
      loadInvestment$ = this.investmentsService.getInvestmentById(risk.investment_ids[0].toString(), this.site);
    }
    if (risk.task_ids.length) {
      loadTask$ = this.loadTaskFromId(risk.task_ids[0]);
    }
    combineLatest([loadInvestment$, loadTask$]).subscribe(([investment, task]) => {
      if (investment && !investment.status.isLocked) {
        this.showActiveAttachementAlert("investment");
      } else if (task && task.state !== TaskState.STATE_DONE && task.state !== TaskState.STATE_CANCELLED) {
        this.showActiveAttachementAlert("task");
      } else {
        this.riskService
          .deleteRisk(risk)
          .pipe(
            switchMap(deletedRisk => {
              if (task) {
                return this.tasksService.deleteTask(task);
              } else {
                return of(null);
              }
            })
          )
          .subscribe(
            () => {
              this.loadRisks();
            },
            err => {
              console.error(err);
              this.errorService.signalError(err);
            }
          );
      }
    });
  }

  private loadTaskFromId(taskId: number): Observable<Task> {
    return this.searchService.generateTasksFilters().pipe(
      switchMap(taskFilters => this.searchService.searchTasks(true)),
      map(tasks => tasks.find(task => task.id === taskId))
    );
  }

  private async showActiveAttachementAlert(attachement: "investment" | "task") {
    let message;
    if (attachement === "investment") {
      message = this.translate.instant("You can't delete this risk because it has an active investment attached to it");
    } else {
      message = this.translate.instant("You can't delete this risk because it has an active task attached to it");
    }
    const activeAttachementAlert = await this.alertCtrl.create({
      message: message,
      buttons: ["Ok"],
    });
    await activeAttachementAlert.present();
  }

  public async showCriticalitySheet(risk: Risk) {
    const criticalitySheet = await this.actionSheetCtrl.create({
      header: this.translate.instant("Risk criticality"),
      buttons: [
        {
          text: this.translate.instant("Major risk"),
          handler: () => {
            if (this.isCriticalityLevelAllowed(RiskCriticality.MAJOR, risk)) {
              this.changeCriticality(risk, RiskCriticality.MAJOR);
            }
          },
          cssClass: this.isCriticalityLevelAllowed(RiskCriticality.MAJOR, risk) ? "" : "disabled",
        },
        {
          text: this.translate.instant("Important risk"),
          handler: () => {
            if (this.isCriticalityLevelAllowed(RiskCriticality.IMPORTANT, risk)) {
              this.changeCriticality(risk, RiskCriticality.IMPORTANT);
            }
          },
          cssClass: this.isCriticalityLevelAllowed(RiskCriticality.IMPORTANT, risk) ? "" : "disabled",
        },
        {
          text: this.translate.instant("Moderate risk"),
          handler: () => {
            if (this.isCriticalityLevelAllowed(RiskCriticality.MODERATE, risk)) {
              this.changeCriticality(risk, RiskCriticality.MODERATE);
            }
          },
          cssClass: this.isCriticalityLevelAllowed(RiskCriticality.MODERATE, risk) ? "" : "disabled",
        },
      ],
    });
    await criticalitySheet.present();
  }

  private isCriticalityLevelAllowed(criticality: number, risk: Risk): boolean {
    const subCategory = risk.sub_category?.id;
    if (!subCategory) {
      console.error("risk has no subcategory");
      return false;
    }
    const subCategoryData = this.riskCategories
      .map(category => category.sub_categories)
      .reduce((acc, val) => acc.concat(val), [])
      .find(subCat => subCat.id === subCategory);
    if (!subCategoryData) {
      console.error("subcategory not found in categories");
      return false;
    }
    if (subCategoryData.criticality === criticality) {
      return true;
    }
    return criticality > risk.criticality;
  }

  private changeCriticality(risk: Risk, criticality: number) {
    this.subscriptions.push(
      this.riskService.updateRisk(risk.id, { criticality: criticality }).subscribe(updated => {
        this.loadRisks();
      })
    );
  }

  public getSubCategoryBudget(subCategoryId: number): string {
    const risk = this.getPerimeterRisk(subCategoryId);
    if (risk?.investment_ids?.length) {
      const investment = this.investments.find(invest => invest.id === risk.investment_ids[0]);
      if (investment) {
        const price = this.currencyPipe.transform(investment.getPrice());
        return this.translate.instant("{{ price }} related to this risk", {
          price: price,
        });
      }
      return null;
    }
    return null;
  }

  private loadInvestments() {
    this.investments = [];
    const risks$ = from(this.perimeterRisks.filter(risk => risk.investment_ids.length));
    risks$
      .pipe(
        concatMap(risk => {
          const investId = risk.investment_ids[0];
          return this.investmentsService.getInvestmentById(investId.toString(), this.site);
        })
      )
      .subscribe(investment => {
        if (investment) {
          this.investments.push(investment);
        }
      });
  }
}
