<ion-item-sliding #slidingItem>
  <ion-item class="risk-item">
    <ion-label position="stacked" class="ion-text-wrap">
      <ion-text
        [color]="getRiskColor(risk?.criticality)"
        class="risk-subcategory-name {{ risk ? 'active-risk' : null }}">
        <ion-icon
          name="stop"
          class="risk-square"
          [color]="getRiskColor(risk ? risk.criticality : riskSubCategory.criticality)"></ion-icon>
        {{ riskSubCategory.name }}
      </ion-text>
    </ion-label>
    <!-- Create or delete risk (Yes/No) -->
    <ion-row>
      <ion-col size="11" (click)="openRiskActionSheet()" class="ion-no-padding">
        <ion-text *ngIf="risk">{{ "Yes" | translate }}</ion-text>
        <ion-text *ngIf="!risk">{{ "No" | translate }}</ion-text>
      </ion-col>
      <ion-col class="ion-align-self-end ion-no-padding ion-text-right" size="1">
        <ion-icon
          name="information-circle"
          [color]="getRiskColor(risk ? risk.criticality : riskSubCategory.criticality)"
          class="risk-information-icon"
          (click)="showInfo()"></ion-icon>
      </ion-col>
    </ion-row>
    <!-- Description / note -->
    <ion-textarea
      type="text"
      [hidden]="!risk"
      [value]="risk?.note"
      (ionBlur)="commentChanged($event)"
      placeholder="{{ 'Add a comment' | translate }}"
      rows="3"></ion-textarea>
    <!-- investment and task -->
    <ion-row class="ion-space-between">
      <ion-col size="10">
        <ion-text class="ion-margin-vertical" (click)="openInvestment()">{{ budget }}</ion-text>
      </ion-col>
      <ion-col size="2" *ngIf="risk?.task_ids.length" (click)="openTask()" class="ion-text-right">
        <i class="fa-light fa-user icon-padding color-reference-data"></i>
        <ion-text color="danger">({{ risk.task_ids.length }})</ion-text>
      </ion-col>
    </ion-row>
  </ion-item>
  <ion-item-options side="end" class="slide-buttons">
    <ion-item-option *ngIf="risk" (click)="changeCriticality(slidingItem)" color="critical">
      <i class="fa-light fa-diamond-exclamation color-white"></i>
    </ion-item-option>
    <ion-item-option *ngIf="risk" (click)="addPicture()" color="readonly">
      <ion-icon name="camera" class="big-icon color-white"></ion-icon>
    </ion-item-option>
    <ion-item-option
      *ngIf="risk && !risk.investment_ids.length"
      (click)="addInvestment(slidingItem)"
      color="mono-perimeter">
      <i class="fa-light fa-euro-sign color-white"></i>
    </ion-item-option>
    <ion-item-option *ngIf="risk && !risk.task_ids.length" (click)="addTask(slidingItem)" color="reference-data">
      <ion-icon name="person-add-outline" color="light"></ion-icon>
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>

<div class="picture-list {{ ios ? 'ios' : null }}" *ngIf="risk?.images.length">
  <app-picture
    *ngFor="let picture of risk.images"
    [picture]="picture"
    class="inline"
    (click)="showPicture(picture)"
    [thumbnail]="true"></app-picture>
</div>

<input
  *ngIf="inBrowser"
  #browserFileUpload
  type="file"
  (click)="browserFileUpload.value = null"
  (change)="addBrowserPicture($event)"
  style="display: none"
  accept="image/*" />
