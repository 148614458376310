<ion-grid class="ion-no-padding">
  <input
    *ngIf="inBrowser"
    #browserFileUpload
    type="file"
    (click)="browserFileUpload.value = null"
    (change)="addBrowserPicture($event)"
    style="display: none"
    accept="image/*" />

  <ion-list lines="full" *ngIf="question">
    <ion-item-sliding #slidingItem>
      <ion-item>
        <ion-label position="stacked" class="ion-text-wrap">
          <div class="text-style2 question-text">{{ question.text }}</div>
          <div class="text-style3">{{ question.help_text_subject }}</div>
        </ion-label>
        <ion-select
          okText="{{ 'OK' | translate }}"
          cancelText="{{ 'Cancel' | translate }}"
          [(ngModel)]="note"
          (ngModelChange)="setNote()"
          interface="action-sheet">
          <ion-select-option [value]="1">{{ "Yes" | translate }}</ion-select-option>
          <ion-select-option [value]="0">{{ "No" | translate }}</ion-select-option>
          <ion-select-option [value]="-1">{{ "I don't know" | translate }}</ion-select-option>
        </ion-select>
        <ion-textarea
          [(ngModel)]="comment"
          type="text"
          *ngIf="note === 1"
          placeholder="{{ 'Add a comment' | translate }}"
          (ngModelChange)="setComment()"></ion-textarea>
      </ion-item>
      <ion-item-options side="end" class="slide-buttons">
        <ion-item-option *ngIf="note === 1" (click)="addPicture()">
          <ion-icon name="camera" class="big-icon"></ion-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
    <ion-item *ngIf="note === 1 && pictures.length">
      <ion-label class="picture-list marginless">
        <app-picture
          [picture]="picture"
          class="inline"
          *ngFor="let picture of pictures"
          (click)="showPicture(picture)"
          [thumbnail]="true"></app-picture>
      </ion-label>
    </ion-item>

    <hr />
  </ion-list>
</ion-grid>
