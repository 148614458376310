<ion-list lines="full">
  <ion-item-sliding *ngFor="let initiative of initiatives" #item>
    <ion-item class="initiative-item" (click)="initiativeClicked.emit(initiative)">
      <ion-label>
        <ion-row>
          <ion-text class="initiative-label text-overflow-ellipsis">{{ initiative.label }}</ion-text>
        </ion-row>
        <ion-row class="ion-justify-content-start">
          <ion-col class="padding-left0">
            <ion-text color="acceptable" class="smaller">
              {{ getStatusName(initiative.status, initiative.savings_start_at) }}
            </ion-text>
          </ion-col>
        </ion-row>
        <!-- savings -->
        <ion-row class="ion-justify-content-between">
          <ion-col class="padding-left0">
            <ion-text color="{{ getColorFromImpact(initiative) }}" class="smaller">
              {{ getExpectedGainLabel(initiative) }}
            </ion-text>
          </ion-col>
        </ion-row>
        <!-- Investment -->
        <ion-row *ngIf="initiative.investment">
          <ion-col class="padding-left0">
            <ion-text class="smaller" color="caption">
              {{ "Linked investment" | translate }} :
              {{ getInvestmentLabel(initiative.investment) }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-label>
    </ion-item>
    <ion-item-options side="end" class="slide-buttons">
      <ion-item-option color="acceptable" (click)="initiativeUnlink(initiative, item)" *ngIf="showUnlinkButton">
        <i class="fal fa-lg fa-unlink"></i>
      </ion-item-option>
      <ion-item-option color="delete" (click)="initiativeDelete(initiative, item)">
        <i class="fal fa-lg fa-trash-alt"></i>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</ion-list>
