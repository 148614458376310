<ion-list lines="full">
  <ion-item-sliding #currentItem>
    <legend-item [legend]="'Current equipment' | translate" (click)="currentAssetClicked()" *ngIf="showCurrentAsset()">
      <span *ngIf="currentAsset">
        {{ currentAsset.label }}
        <span *ngIf="currentAsset.step === archivedStep">({{ "archived" | translate }})</span>
      </span>
      <span *ngIf="!currentAsset">-</span>
    </legend-item>
    <ion-item-options *ngIf="!investment.status.isClosed && !preventSwiping" class="slide-buttons">
      <ion-item-option *ngIf="currentAsset" color="asset" (click)="detachInvestment(currentItem)">
        <i class="fal fa-lg fa-unlink"></i>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
  <ion-item-sliding #nextItem>
    <legend-item [legend]="nextAssetDesignation" (click)="nextAssetClicked()" *ngIf="showNextAsset()">
      <span *ngIf="nextAsset">{{ nextAsset.label }} ({{ "future equipment" | translate }})</span>
      <span *ngIf="!nextAsset">-</span>
    </legend-item>
    <ion-item-options *ngIf="!investment.status.isClosed && !preventSwiping" class="slide-buttons">
      <ion-item-option *ngIf="nextAsset" color="critical" (click)="deleteNextAsset(nextItem)">
        <i class="fal fa-lg fa-trash-alt"></i>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</ion-list>
