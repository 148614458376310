<ion-list *ngIf="mainPerimeter" lines="full">
  <ng-container *ngFor="let perimeterElt of searchedPerimeters">
    <ion-item-sliding #item *ngIf="!perimeterElt.perimeter.level_parent || childrenPerimetersMode">
      <ion-item color="{{ getParentColor(perimeterElt) }}">
        <ion-label class="perimeter">
          <div class="chevron-button-container">
            <ion-buttons *ngIf="!hideChildren">
              <ion-button
                class="chevron-button"
                *ngIf="!perimeterElt.perimeter.level_parent && perimeterElt.hasLevelChildren"
                (click)="toggleMultiPerimeterChildren(perimeterElt.perimeter)">
                <i class="fal fa-chevron-right" *ngIf="isCollapsed(perimeterElt.perimeter.id)"></i>
                <i class="fal fa-chevron-down" *ngIf="!isCollapsed(perimeterElt.perimeter.id)"></i>
              </ion-button>
            </ion-buttons>
            <div *ngIf="!hideChildren && perimeterElt.hasLevelChildren">({{ getChildrenCount(perimeterElt) }})</div>
          </div>

          <!-- Thumbnail picture -->
          <div class="picture" (click)="pictureClicked($event, perimeterElt.perimeter)">
            <app-picture
              [picture]="
                perimeterElt.perimeter.pictures.length > 0 ? perimeterElt.perimeter.pictures[0] : pictureEmpty
              "></app-picture>
          </div>

          <div class="info" (click)="selectPerimeter(perimeterElt.perimeter)">
            <div class="text-overflow-ellipsis cropped-label">
              <span class="text-style5">
                {{ perimeterElt.perimeter.name }}
              </span>
            </div>
            <div class="assets ion-text-wrap" style="text-transform: lowercase">
              {{ getLinkedAssetsSentence(perimeterElt) }}
              <ion-text color="danger" *ngIf="getCriticalAssetsCount(perimeterElt)">
                {{ getCriticalAssetsSentence(perimeterElt) }}
              </ion-text>
            </div>
            <div class="budget">{{ perimeterElt.budget | currency }} {{ "planned" | translate }}</div>
            <div *ngIf="risksEnabled$ | async" class="risk-sentence {{ getRiskColor(perimeterElt.perimeter.id) }}">
              {{ getRiskSentence(perimeterElt.perimeter.id) }}
            </div>
          </div>
        </ion-label>
      </ion-item>
      <ion-item-options *ngIf="showSwipeButtons" class="slide-buttons">
        <ion-item-option
          *ngIf="!perimeterElt.perimeter.level_parent && isBuilding(perimeterElt.perimeter)"
          (click)="addMonoPerimeter(item, perimeterElt.perimeter)"
          color="mono-perimeter"
          class="add-perimeter">
          <i class="fal fa-plus"></i>
        </ion-item-option>
        <ion-item-option (click)="deletePerimeter(item, perimeterElt)" color="delete">
          <i class="fal fa-trash-alt"></i>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
    <!-- Perimeter type section -->
    <div [hidden]="!perimeterElt.perimeterTypeSections || isCollapsed(perimeterElt.perimeter.id)">
      <div
        *ngFor="let perimeterTypeSection of perimeterElt.perimeterTypeSections"
        [ngClass]="showSection(perimeterElt, perimeterTypeSection) ? 'green-children' : ''">
        <ion-row
          class="children-divider"
          (click)="toggleSection(perimeterTypeSection.id)"
          *ngIf="showSection(perimeterElt, perimeterTypeSection)">
          <ion-col size="2" size-lg="1">
            <i class="fal fa-chevron-right" *ngIf="isSectionCollapsed(perimeterTypeSection.id)"></i>
            <i class="fal fa-chevron-down" *ngIf="!isSectionCollapsed(perimeterTypeSection.id)"></i>
            <i class="fal fa-baby children-icon"></i>
            ({{ perimeterTypeSection.perimeters.length }})
          </ion-col>
          <ion-col>
            <ion-note class="text-underline">{{ perimeterTypeSection.name }}</ion-note>
          </ion-col>
          <ion-col size="2" size-lg="1"></ion-col>
        </ion-row>
        <div *ngIf="!isSectionCollapsed(perimeterTypeSection.id) || !showSection(perimeterElt, perimeterTypeSection)">
          <!-- Children -->
          <ion-item-sliding #item *ngFor="let perimeterElt of perimeterTypeSection.perimeters">
            <ion-item color="{{ isSelected(perimeterElt.perimeter) ? 'selected-blue' : 'selected-blue-child' }}">
              <ion-label class="perimeter">
                <div class="chevron-button-container">
                  <ion-buttons>
                    <ion-button>
                      <i class="fal fa-baby children-icon"></i>
                    </ion-button>
                  </ion-buttons>
                </div>

                <!-- Thumbnail picture -->
                <div class="picture" (click)="pictureClicked($event, perimeterElt.perimeter)">
                  <app-picture
                    [picture]="
                      perimeterElt.perimeter.pictures.length > 0 ? perimeterElt.perimeter.pictures[0] : pictureEmpty
                    "></app-picture>
                </div>

                <div class="info" (click)="selectPerimeter(perimeterElt.perimeter)">
                  <div class="text-overflow-ellipsis cropped-label">
                    <span class="text-style5">
                      {{ perimeterElt.perimeter.name }}
                    </span>
                  </div>
                  <div class="assets" style="text-transform: lowercase">
                    {{ getLinkedAssetsSentence(perimeterElt) }}
                  </div>
                  <div class="budget">{{ perimeterElt.budget | currency }} {{ "planned" | translate }}</div>
                </div>
              </ion-label>
            </ion-item>
            <ion-item-options *ngIf="showSwipeButtons" class="slide-buttons">
              <ion-item-option (click)="deletePerimeter(item, perimeterElt)" color="delete">
                <i class="fal fa-trash-alt"></i>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </div>
      </div>
    </div>
  </ng-container>
</ion-list>
