<ion-header *ngIf="!childComponentMode">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <i class="fa-solid fa-circle-chevron-left back-icon"></i>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ "Configure category and sub-category" | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list lines="full">
    <input-item [legend]="'Super-category' | translate">
      <ion-select
        *ngIf="superCategories$ | async as superCategories"
        okText="{{ 'OK' | translate }}"
        cancelText="{{ 'Cancel' | translate }}"
        [(ngModel)]="selectedSuperCategoryId"
        placeholder="-"
        (ngModelChange)="superCategoryChanged(true, superCategories)"
        interface="action-sheet"
        [disabled]="readOnly">
        <ion-select-option *ngFor="let superCategory of superCategories" [value]="superCategory.id">
          {{ superCategory.name }}
        </ion-select-option>
      </ion-select>
    </input-item>

    <ion-row class="grey-divider" (click)="showOptionalFields = !showOptionalFields" *ngIf="!needsFullNomenclature">
      <ion-col>
        <ion-note color="primary">
          <span *ngIf="!showOptionalFields">(+)</span>
          <span *ngIf="showOptionalFields">(-)</span>
          {{ "See more" | translate }}
        </ion-note>
      </ion-col>
    </ion-row>

    <span [hidden]="!showOptionalFields">
      <input-item [legend]="'Category' | translate">
        <ion-select
          okText="{{ 'OK' | translate }}"
          cancelText="{{ 'Cancel' | translate }}"
          [(ngModel)]="selectedCategoryId"
          placeholder="-"
          (ngModelChange)="categoryChanged(true)"
          interface="action-sheet"
          [disabled]="readOnly || selectedSuperCategoryId === null">
          <ion-select-option *ngFor="let category of categories" [value]="category.id">
            {{ category.name }}
          </ion-select-option>
        </ion-select>
      </input-item>

      <input-item [legend]="'Sub-category' | translate">
        <ion-select
          okText="{{ 'OK' | translate }}"
          cancelText="{{ 'Cancel' | translate }}"
          [(ngModel)]="selectedSubCategoryId"
          placeholder="-"
          (ngModelChange)="subCategoryChanged()"
          interface="action-sheet"
          [disabled]="readOnly || !selectedCategoryId">
          <ion-select-option *ngFor="let subCategory of subCategories" [value]="subCategory.id">
            {{ subCategory.name }}
          </ion-select-option>
        </ion-select>
      </input-item>
    </span>
  </ion-list>
</ion-content>

<ion-footer *ngIf="hasPendingChanges && !childComponentMode">
  <app-footer-next-button
    nextButton
    (next)="validateChanges()"
    [label]="'validate'"
    [enabled]="isValid()"
    color="investment"></app-footer-next-button>
</ion-footer>
